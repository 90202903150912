<gz-layout [maxWidth]="1200">
  <div class="inquiry-frame">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <span class="header-txt">제목</span>
          <nz-input-group class="flex" style="align-items: center;">
            <nz-select formControlName="title" style="width: 100%;">
              <nz-option *ngFor="let data of inquiryTitleList" [nzValue]="data" [nzLabel]="data">
              </nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <span class="header-txt">내용</span>
          <nz-input-group class="flex" style="align-items: center;">
            <textarea nz-input formControlName="body" class="input" style="height: 150px;"></textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div class="submit-btn-wrapper">
        <button class="submit-btn" nz-button [nzType]="'primary'" [disabled]="buttonDisabled">생성</button>
      </div>
    </form>
  </div>
</gz-layout>