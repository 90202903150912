import { Component, Input, OnInit } from '@angular/core';
import { Casino, CasinoProvider, CasinoUserMoneyInfo, User } from '@models';
import { CasinoService, CommonService, ContentService, ModalService, UserService } from '@services';
import { NzTableQueryParams } from 'ng-zorro-antd';

@Component({
  selector: 'gz-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.sass'],
})
export class CasinoComponent implements OnInit {
  navigationType = 'CASINO';

  isVisible = false;

  casinoList: Casino[] = [];

  chargeMoney: number;
  refundMoney: number;
  chargeBtnLoading = false;

  refundBtnLoading = false;
  refreshBtnLoading = false;

  @Input() casinoId;
  dataLoaded = false;
  casinoLoaded = false;

  user = new User();
  casinoUser: CasinoUserMoneyInfo = new CasinoUserMoneyInfo();

  moneyLogList: any[] = [];
  pageIdx = 1;
  pageSize = 20;
  totalCount = 0;
  loading = false;

  constructor(
    public commonService: CommonService,
    public casinoService: CasinoService,
    private contentService: ContentService,
    private userService: UserService,
    private modalService: ModalService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.casinoId) {
      await this.onCasinoIdChange();
    }
    await this.loadCasino();
    this.loadData();
  }

  loadData = async () => {
    this.dataLoaded = false;
    this.user = await this.userService.getCachedUserInfo();
    await this.loadCasinoMoneyLogList();
    this.dataLoaded = true;
  };

  loadCasino = async () => {
    this.casinoList = await this.casinoService.getCasinoList();
    if (this.casinoList.length > 0 && !this.casinoId) {
      this.casinoId = this.casinoList[0].casinoId;
    }
  };

  loadCasinoMoneyLogList = async () => {
    this.moneyLogList = await this.casinoService.getMoneyTransferLogList(
      this.casinoId,
      this.pageIdx - 1,
      this.pageSize,
    );
    this.totalCount = await this.casinoService.getMoneyTransferLogListCount(this.casinoId);
  };

  onChargeBtnClick = async () => {
    if (this.chargeBtnLoading) {
      return;
    }
    this.chargeBtnLoading = true;
    try {
      if (this.chargeMoney > this.casinoUser.casinoMoney) {
        this.modalService.warning('경고', '요청 금액이 너무 많습니다.');
        this.chargeBtnLoading = false;
        return;
      }
      this.casinoUser = await this.casinoService.subCasinoBalance(this.casinoId, this.chargeMoney);
      await this.loadCasinoMoneyLogList();
      this.chargeMoney = null;

      const user = await this.userService.getUserInfo();
      this.contentService.onUserChange(user);
    } catch (ex) {}
    setTimeout(() => {
      this.chargeBtnLoading = false;
    }, 2000);
  };

  onRefundBtnClick = async () => {
    if (this.refundBtnLoading) {
      return;
    }
    this.refundBtnLoading = true;
    try {
      if (this.refundMoney > this.casinoUser.userMoney) {
        this.modalService.warning('경고', '요청 금액이 너무 많습니다.');
        this.chargeBtnLoading = false;
        return;
      }
      this.casinoUser = await this.casinoService.addCasinoBalance(this.casinoId, this.refundMoney);
      await this.loadCasinoMoneyLogList();
      this.refundMoney = null;

      const user = await this.userService.getUserInfo();
      this.contentService.onUserChange(user);
    } catch (ex) {}
    setTimeout(() => {
      this.refundBtnLoading = false;
    }, 2000);
  };

  onEnterSlot = () => {
    this.casinoService.openGameWindow(`/gameGroup/casino/${this.casinoId}`);
  };

  onClickProvider = async (provider: CasinoProvider) => {
    const url = await this.casinoService.getCasinoLobbyUrl(this.casinoId, provider.providerId);
    this.casinoService.openLobbyWindow(url);
  };

  onRefresh = async () => {
    this.refreshBtnLoading = true;
    try {
      this.casinoUser = await this.casinoService.getCasinoBalance(this.casinoId);
    } catch (ex) {}
    this.refreshBtnLoading = false;
  };

  onCasinoIdChange = async () => {
    this.casinoLoaded = false;
    try {
      this.casinoUser = await this.casinoService.getCasinoBalance(this.casinoId);
    } catch (ex) {}
    this.casinoLoaded = true;
  };

  addChargeMoney = (money: number) => {
    if (!this.chargeMoney) {
      this.chargeMoney = 0;
    }
    this.chargeMoney += money;
  };

  addRefundMoney = (money: number) => {
    if (!this.refundMoney) {
      this.refundMoney = 0;
    }
    this.refundMoney += money;
  };

  formatComma = (num: number) => this.commonService.formatNumber(`${num}`);
  parseComma = (value: string) => this.commonService.formatToNumber(value);

  hideModal = () => {
    this.isVisible = false;
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    await this.loadCasinoMoneyLogList();
  };

  deleteWholeMoneyLog = async () => {
    try {
      await this.modalService.deleteConfirm('전체삭제', '머니이동 이력을 전체 삭제하시겠습니까?');
      await this.casinoService.deleteWholeMoneyTransferLog(this.casinoId);
      this.moneyLogList = [];
    } catch (ex) {}
  };

  deleteRow = async (id: number): Promise<void> => {
    this.loading = true;
    try {
      await this.casinoService.updateMoneyTransferLog(this.casinoId, id);
      this.totalCount -= 1;
      this.moneyLogList = this.moneyLogList.filter(d => d.id !== +id);
    } catch (ex) {}
    this.loading = false;
  };

  get chargeDisabled(): boolean {
    return (
      !this.chargeMoney || this.chargeMoney <= 0 || this.casinoUser.casinoMoney < this.chargeMoney
    );
  }

  get refundDisabled(): boolean {
    return (
      !this.refundMoney || this.refundMoney <= 0 || this.casinoUser.userMoney < this.refundMoney
    );
  }

  get selectedCasino() {
    return this.casinoList.find(casino => casino.casinoId === this.casinoId);
  }

  getAbsAmount = (amount: number) => {
    return Math.abs(amount);
  };
}
