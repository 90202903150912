import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Notice } from '@models';
import {
  CommonService,
  ContentService,
  ModalService,
  NavigationService,
  NoticeService,
} from '@services';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { DetailNoticeComponent } from './detail';

@Component({
  selector: 'gz-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.sass'],
})
export class NoticeComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();

  loading = false;

  pageIdx = 1;
  totalCount = 0;
  pageSize = 20;

  noticeLists: Notice[] = [];
  subs: Subscription[] = [];

  constructor(
    public commonService: CommonService,
    private contentService: ContentService,
    private router: Router,
    private noticeService: NoticeService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.contentService.noticeChange$.subscribe(() => {
        this.loadNoticeList();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        await this.loadNoticeList();
      });
    this.loadNoticeList();
  }

  loadNoticeList = async () => {
    this.loading = true;
    try {
      this.totalCount = await this.noticeService.getNoticeListCount(
        this.pageIdx - 1,
        this.pageSize,
      );
      this.noticeLists = await this.noticeService.getNoticeList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  detailNotice = (noticeId: number) => {
    this.modalService.create('공지사항 상세', DetailNoticeComponent, 700, {
      noticeId,
    });
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    this.loadNoticeList();
  };

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.map(sub => sub.unsubscribe());
  }
}
