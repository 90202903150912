import { Component, Input, OnInit } from '@angular/core';
import { User, BettingSummary, BettingLog, Game } from '@models';
import { CommonService, GameService, PartnerService } from '@services';

@Component({
  selector: 'gz-betting-log',
  templateUrl: './betting-log.component.html',
  styleUrls: ['./betting-log.component.sass'],
})
export class BettingLogComponent implements OnInit {
  @Input() user: User;
  @Input() isBrief = false;

  gameGroupId = '0';
  gameId = '0';

  pageIdx = 1;
  pageSize = 10;
  totalCount = 0;
  loading = false;

  interval: any;
  gameList: Game[] = [];

  searchBtnLoading = false;

  bettingLogLists: BettingLog[] = [];

  startDate: Date = new Date();

  constructor(
    public commonService: CommonService,
    private partnerService: PartnerService,
    public gameService: GameService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.gameList = await this.gameService.getGameLists(0);
    this.loadBettingLog();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageIdx = pageIdx;
    await this.loadBettingLog();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadBettingLog();
  };

  onSearchBtnClick = async () => {
    this.searchBtnLoading = true;
    try {
      await this.loadBettingLog();
    } catch (ex) {}
    this.searchBtnLoading = false;
  };

  loadBettingLog = async () => {
    this.loading = true;
    try {
      const gameGroupId = Number(this.gameGroupId);
      const gameId = Number(this.gameId);
      const promises = [];
      promises.push(
        this.partnerService.getBettingLogListCount(
          this.user.userId,
          gameGroupId,
          gameId,
          this.startDate,
        ),
      );
      promises.push(
        this.partnerService.getBettingLogList(
          this.user.userId,
          gameGroupId,
          gameId,
          this.pageIdx - 1,
          this.pageSize,
          this.startDate,
        ),
      );
      const result = await Promise.all(promises);
      this.totalCount = result[0];
      let bettingLogLists = result[1];
      bettingLogLists = bettingLogLists.map(bettingLog => {
        bettingLog.raceTitle = this.gameService.getRaceTitle(
          bettingLog.gameId,
          bettingLog.raceTitle,
          bettingLog.dailyNo,
        );
        return bettingLog;
      });
      this.bettingLogLists = bettingLogLists;
    } catch (ex) {}
    this.loading = false;
  };
}
