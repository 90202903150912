import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CopyUser, Game } from '@models';
import { NumberRegex } from '@regex';
import { CommonService, GameService, ModalService, UserService } from '@services';

@Component({
  selector: 'gz-copy-form',
  templateUrl: './copy-form.component.html',
  styleUrls: ['./copy-form.component.sass'],
})
export class CopyFormComponent implements OnInit, OnChanges, OnDestroy {
  loading = false;
  validateForm!: FormGroup;
  userInfo: CopyUser;
  gameList: Game[] = [];

  dual = false;

  @Input() gameGroupId: number;
  @Input() gameId: number;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private modalService: ModalService,
    private gameService: GameService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.validateForm = this.fb.group({
      targetLoginId: [null, [Validators.required]],
      targetSolutionId: [null, [Validators.required]],
      copyRate: [
        1,
        [
          Validators.required,
          Validators.pattern(NumberRegex),
          Validators.min(0),
          Validators.max(1000),
        ],
      ],
      copyLimit: [0, [Validators.required, Validators.min(0), Validators.pattern(NumberRegex)]],
      copyEnabled: [false, [Validators.required]],
      copyReverse: [false, [Validators.required]],
    });
  }

  async ngOnChanges(): Promise<void> {
    if (!this.gameId || !this.gameGroupId) {
      return;
    }

    let targetLoginId = '';
    let copyRate = 1;
    let copyLimit = 0;
    let copyEnabled = false;
    let copyReverse = false;

    const user = await this.userService.getCachedUserInfo();
    const targetSolutionId = user.solutionId;

    const gameList = await this.gameService.getGameLists(this.gameGroupId);
    const game = gameList.find(elem => elem.gameId === this.gameId);
    this.dual = game.dual;

    this.userInfo = await this.userService.getCopyInfo(this.gameGroupId, this.gameId);
    if (this.userInfo) {
      targetLoginId = this.userInfo.targetLoginId;
      copyRate = this.userInfo.copyRate;
      copyLimit = this.userInfo.copyLimit;
      copyEnabled = this.userInfo.copyEnabled;
      copyReverse = this.userInfo.copyReverse;
    } else {
      this.userInfo = {
        targetLoginId,
        copyRate,
        copyLimit,
        copyEnabled,
        copyReverse,
        targetSolutionId,
      };
    }

    this.validateForm.setValue({
      targetLoginId,
      targetSolutionId,
      copyRate,
      copyLimit,
      copyEnabled,
      copyReverse,
    });
  }

  submitForm = async (): Promise<void> => {
    this.loading = true;
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      try {
        const userInfo = Object.assign(this.userInfo, this.validateForm.value);
        if (!this.userInfo.copyEnabled) {
          this.userInfo.copyReverse = false;
        }
        await this.userService.updateCopyInfo(userInfo, this.gameGroupId, this.gameId);
        this.modalService.success('성공', '수정 성공');
      } catch (ex) {}
    }
    this.loading = false;
  };

  get showReverseCopy(): boolean {
    if (!this.validateForm) {
      return false;
    }
    const { copyEnabled } = this.validateForm.value;
    return copyEnabled && this.dual;
  }

  formatComma = (value: string) => this.commonService.formatNumber(value);
  parseComma = (value: string) => this.commonService.formatToNumber(value);

  ngOnDestroy(): void {}
}
