import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService, ModalService, PartnerService, UserService } from '@services';
import { TwoDigitNumberRegex } from '@regex';
import { Game, GameGroup, User } from '@models';

@Component({
  selector: 'gz-user-point-rate-form',
  templateUrl: './user-point-rate-form.component.html',
  styleUrls: ['./user-point-rate-form.component.sass'],
})
export class UserPointRateFormComponent implements OnInit, OnChanges {
  @Input() gameGroupId: number;
  @Input() user: User;

  validateForm!: FormGroup;
  loading = false;

  formLoaded = false;

  gameGroup: GameGroup;

  games: Game[][] = [];

  disabled = true;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private modalService: ModalService,
    private partnerService: PartnerService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.clearForm();
  }

  clearForm = () => {
    this.validateForm = this.fb.group({
      gameList: this.fb.array([]),
    });
  };

  async ngOnChanges(): Promise<void> {
    if (!this.gameGroupId) {
      return;
    }
    if (!this.user) {
      return;
    }
    await this.checkDisabled();
    await this.loadPoint();
    this.clearForm();
    this.generateGames();
    this.generateForms();
  }

  checkDisabled = async () => {
    const user = await this.userService.getUserInfo();
    if (user.userId === this.user.userId) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  };

  loadPoint = async () => {
    this.gameGroup = await this.partnerService.getPartnerPointRate(
      this.user.userId,
      this.gameGroupId,
    );
  };

  generateGames = () => {
    this.games = this.commonService.chunkArray(3, this.gameGroup.gameList);
  };

  generateForms = async () => {
    this.gameGroup.gameList.forEach(game => {
      this.gameList.push(
        this.fb.group({
          gameId: game.gameId,
          gameName: game.gameName,
          betPointRate: [
            { value: game.betPointRate, disabled: this.disabled },
            [Validators.required, Validators.min(0), Validators.pattern(TwoDigitNumberRegex)],
          ],
          winPointRate: [
            { value: game.winPointRate, disabled: this.disabled },
            [Validators.required, Validators.min(0), Validators.pattern(TwoDigitNumberRegex)],
          ],
        }),
      );
    });
  };

  submitForm = async (): Promise<void> => {
    if (this.disabled) {
      this.modalService.error('수정 에러', '본인의 수수료는 수정 불가합니다.', true);
      return;
    }
    this.loading = true;
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      try {
        const { gameList } = this.validateForm.value;
        const updated = Object.assign(this.gameGroup.gameList, gameList);
        this.gameGroup.gameList = updated;
        await this.partnerService.updatePartnerPointRate(
          this.user.userId,
          this.gameGroup,
          this.gameGroupId,
        );
        this.modalService.success('성공', '수정 완료', true);
      } catch (ex) {}
    }
    this.loading = false;
  };

  get gameList(): FormArray {
    return this.validateForm.get('gameList') as FormArray;
  }
}
