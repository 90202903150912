import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../user';
import { ContentService } from '../content';

@Injectable()
export class PartnerGuardService implements CanActivate {
  constructor(private contentService: ContentService, private userService: UserService) {}

  async canActivate(): Promise<boolean> {
    const user = await this.userService.getCachedUserInfo();

    if (!user.partnerEnabled) {
      this.contentService.signOut();
      return false;
    }

    return true;
  }
}
