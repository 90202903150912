<section class="game-tab-section">
  <div *ngIf="casinoBetEnabled" [ngClass]="btnClass('CASINO')" data-target="casino" (click)="onTabClick('CASINO')">
    <div class="casino-btn btn-panel w-ba">
      <span class="category">라이브카지노</span>
      <span class="ml indicator" nz-icon nzType="caret-down" nzTheme="fill"></span>
    </div>
  </div>
  <div *ngIf="powerBallBetEnabled" [ngClass]="btnClass('MINI_GAME')" data-target="mini-game"
    (click)="onTabClick('MINI_GAME')">
    <div class="mini-game-btn btn-panel w-ba">
      <span class="category">미니게임</span>
    </div>
  </div>
  <div *ngIf="slotBetEnabled" [ngClass]="btnClass('SLOT')" data-target="slot" (click)="onTabClick('SLOT')">
    <div class="slot-btn btn-panel w-ba">
      <span class="mr indicator" nz-icon nzType="caret-down" nzTheme="fill"></span>
      <span class="category">슬롯게임</span>
    </div>
  </div>
</section>