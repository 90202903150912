import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Note, NoteType } from '@models';
import { ModalService } from '../modal';
import { AudioService } from '../audio';

@Injectable()
export class NoteService {
  url = '/api/message';

  checkingImportantNoted = false;

  noteCount: number;

  constructor(private http: HttpClient, private modalService: ModalService, private audioService: AudioService) {}

  getNote = (noteId: number): Promise<Note> => {
    return this.http.get<Note>(`${this.url}/${noteId}`).toPromise();
  };

  getNoteList = (
    pageIndex: number,
    pageSize: number,
    read?: boolean,
    noteType?: NoteType,
  ): Promise<Note[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (read === true || read === false) {
      params = params.append('read', `${read}`);
    }
    if (noteType) {
      params = params.append('messageType', noteType);
    }
    return this.http
      .get<Note[]>(`${this.url}`, { params })
      .toPromise();
  };

  getNoteListCount = (
    pageIndex: number,
    pageSize: number,
    read?: boolean,
    noteType?: NoteType,
  ): Promise<number> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (read === true || read === false) {
      params = params.append('read', `${read}`);
    }
    if (noteType) {
      params = params.append('messageType', noteType);
    }
    return this.http
      .get<number>(`${this.url}/count`, { params })
      .toPromise();
  };

  getImportantNoteCount = (): Promise<number> => {
    let params = new HttpParams();
    params = params.append('messageType', NoteType.IMPORTANT).append('read', 'false');
    return this.http
      .get<number>(`${this.url}/count`, { params })
      .toPromise();
  };

  getCacheUnreadNoteCount = async (): Promise<number> => {
    if (!this.noteCount) {
      return this.getUnreadNoteCount();
    }
    return this.noteCount;
  };

  getUnreadNoteCount = async (): Promise<number> => {
    let params = new HttpParams();
    params = params.append('read', 'false');
    const noteCount = await this.http
      .get<number>(`${this.url}/count`, { params })
      .toPromise();
    this.noteCount = noteCount;
    return noteCount;
  };

  checkImportantNote = async () => {
    if (this.checkingImportantNoted) {
      return false;
    }
    try {
      this.checkingImportantNoted = true;
      const unReadCount = await this.getImportantNoteCount();
      if (unReadCount !== 0) {
        this.audioService.playNote();
        this.modalService.openNotePage();
        this.checkingImportantNoted = false;
        return false;
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      this.checkingImportantNoted = false;
    }
    return true;
  }

  deleteNote = (noteId: number) => {
    return this.http.delete(`${this.url}/${noteId}`).toPromise();
  };

  deleteAllNote = () => {
    return this.http.delete(`${this.url}/all`).toPromise();
  };

  readAllNote = () => {
    return this.http.get(`${this.url}/all`).toPromise();
  };

  noteTypeToStr = (noteType: NoteType) => {
    switch (noteType) {
      case NoteType.NORMAL:
        return '일반';
      case NoteType.IMPORTANT:
        return '중요';
      default:
        return '알수없음';
    }
  };

  readToStr = (read: boolean) => {
    return read ? '읽음' : '안읽음';
  };
}
