import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CasinoProvider } from '@models';
import { CasinoService, GameService } from '@services';

@Component({
  selector: 'gz-casino-provider-tab',
  templateUrl: './casino-provider-tab.component.html',
  styleUrls: ['./casino-provider-tab.component.sass'],
})
export class CasinoProviderTabComponent implements OnInit {
  providerList: CasinoProvider[] = [];

  providerCode: string;

  @Input() casinoId = 0;

  @Output() providerCodeChange = new EventEmitter<string>();

  constructor(private casinoService: CasinoService) {}

  async ngOnInit(): Promise<void> {
    this.providerList = await this.casinoService.getCasinoProviderList(this.casinoId, 'SLOT');
    if (this.providerList.length > 0) {
      const provider = this.providerList[0];
      this.onChangeValue(provider.providerCode);
    }
  }

  onChangeValue = (providerCode: string) => {
    this.providerCode = providerCode;
    this.providerCodeChange.emit(providerCode);
  };

  tabBtnClasses = (providerCode: string): string[] => {
    return this.providerCode === providerCode ? ['tab-btn'] : ['tab-btn', 'un-selected'];
  };
}
