import { Component, Input } from '@angular/core';
import { CommonService } from '@services';

@Component({
  selector: 'gz-clip-board',
  templateUrl: './clip-board.component.html',
  styleUrls: ['./clip-board.component.sass'],
})
export class ClipBoardComponent {
  @Input() value = '';

  constructor(private commonService: CommonService) {}

  copyToClipboard = () => {
    this.commonService.copyToClipboard(this.value);
    alert('복사 성공');
  };
}
