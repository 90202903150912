import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Casino } from '@models';

@Component({
  selector: 'gz-casino-tab',
  templateUrl: './casino-tab.component.html',
  styleUrls: ['./casino-tab.component.sass'],
})
export class CasinoTabComponent {
  @Input() casinoList: Casino[] = [];

  @Input() casinoId = 0;
  @Output() casinoIdChange = new EventEmitter<number>();

  ngOnInit(): void {
    if (this.casinoId) {
      this.casinoIdChange.emit(this.casinoId);
    } else {
      this.casinoIdChange.emit(this.casinoList[0]?.casinoId || 0);
    }
  }

  onChangeValue = (casinoId: number) => {
    this.casinoId = casinoId;
    this.casinoIdChange.emit(this.casinoId);
  };

  tabBtnClasses = (casinoId: number): string[] => {
    return this.casinoId === casinoId
      ? ['tab-btn', 'form-col']
      : ['tab-btn', 'un-selected', 'form-col'];
  };
}
