import { Component, Input, OnInit } from '@angular/core';

import { CommonService, UserService, AuthService, PartnerService } from '@services';
import { ChargeType, MoneyState, UserCharge, User } from '@models';

@Component({
  selector: 'gz-charge-log',
  templateUrl: './charge-log.component.html',
  styleUrls: ['./charge-log.component.sass'],
})
export class ChargeLogComponent implements OnInit {
  moneyState: MoneyState | '0' = '0';
  chargeType: ChargeType = ChargeType.CHARGE_ALL;

  @Input() user: User;

  state = MoneyState;

  pageIdx = 1;
  pageSize = 10;
  totalCount = 0;
  loading = false;

  searchBtnLoading = false;

  chargeLogLists: UserCharge[] = [];

  dateRange = [new Date(), new Date()];

  constructor(
    public authService: AuthService,
    public commonService: CommonService,
    public userService: UserService,
    private partnerService: PartnerService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadChargeList();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageIdx = pageIdx;
    await this.loadChargeList();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadChargeList();
  };

  onSearchBtnClick = async () => {
    this.searchBtnLoading = true;
    try {
      await this.loadChargeList();
    } catch (ex) {}
    this.searchBtnLoading = false;
  };

  loadChargeList = async () => {
    this.loading = true;
    try {
      const promises = [];
      promises.push(
        this.partnerService.getUserMoneyListCount(
          this.pageIdx - 1,
          this.pageSize,
          this.user.userId,
          'charge',
          this.dateRange[0],
          this.dateRange[1],
          this.moneyState,
          this.chargeType,
        ),
      );
      promises.push(
        this.partnerService.getUserMoneyList(
          this.pageIdx - 1,
          this.pageSize,
          this.user.userId,
          'charge',
          this.dateRange[0],
          this.dateRange[1],
          this.moneyState,
          this.chargeType,
        ),
      );

      const result = await Promise.all(promises);

      this.totalCount = result[0];
      this.chargeLogLists = result[1];
    } catch (ex) {}
    this.loading = false;
  };
}
