<div class="user-info-wrapper">
  <div class="user-edit-tab-wrapper">
    <nz-tabset class="user-edit-tab" [nzTabPosition]="'top'" [nzType]="'card'" [nzSelectedIndex]="index"
      [nzSize]="'small'">
      <nz-tab [nzTitle]="loginId" nzDisabled></nz-tab>
      <nz-tab [nzTitle]="'수수료율'">
        <ng-template nz-tab>
          <gz-user-point-rate [user]="user" [casinoList]="casinoList"></gz-user-point-rate>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'배팅내역'">
        <ng-template nz-tab>
          <gz-user-betting-log [user]="user" [casinoList]="casinoList"
            [gameGroupList]="gameGroupList"></gz-user-betting-log>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'충전내역'">
        <ng-template nz-tab>
          <gz-charge-log [user]="user"></gz-charge-log>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'환전내역'">
        <ng-template nz-tab>
          <gz-refund-log [user]="user"></gz-refund-log>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'수수료 내역'">
        <ng-template nz-tab>
          <gz-user-point-log [user]="user"></gz-user-point-log>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'잔고 내역'">
        <ng-template nz-tab>
          <gz-user-balance-log [partner]="partner" [user]="user"></gz-user-balance-log>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</div>