import { Injectable } from '@angular/core';

@Injectable()
export class AudioService {
  audio = new Audio();

  constructor() {
    this.audio.load();
  }

  playNote = () => {
    if (!this.audio.paused) {
      this.audio.pause();
    }
    this.audio.autoplay = true;
    this.audio.src = '../../assets/voices/note';
    this.audio.play();
  };
}
