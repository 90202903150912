import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { RxStompService } from '@stomp/ng2-stompjs';

import { PipeModule, ServiceModule } from '@services';
import {
  SharedModule,
  NoticeComponent,
  DetailNoticeComponent,
  PopupNoticeComponent,
  PopupNoticeListComponent,
  NoticeListComponent,
} from '@components';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    ServiceModule,
    PipeModule,
  ],
  declarations: [
    AppComponent,
    NoticeComponent,
    NoticeListComponent,
    DetailNoticeComponent,
    PopupNoticeComponent,
    PopupNoticeListComponent,
    NoticeListComponent,
  ],
  providers: [RxStompService],
  bootstrap: [AppComponent],
})
export class AppModule {}
