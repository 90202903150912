<div class="deposit-container">
  <div class="deposit">아이디 : {{user.username}}</div>
  <div class="deposit">
    <span>보유금 : {{commonService.formatNumber(user.userMoney)}} 원</span>
    <gz-clip-board [value]="user.userMoney"></gz-clip-board>
  </div>
  <div class="deposit">지급 대상 : {{depositUser.username}}</div>
  <div class="deposit">
    <span>지급 대상 보유금 : {{commonService.formatNumber(depositUser.userMoney)}} 원</span>
  </div>
  <div class="deposit button-wrapper">
    <button nz-button nzType="default" (click)="chargeAll()">전액</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(10000)">1만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(50000)">5만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(100000)">10만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(500000)">50만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(1000000)">1백만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(5000000)">5백만</button>
    <button nz-button nzType="primary" (click)="addChargeMoney(10000000)">1천만</button>
    <button nz-button nzType="primary" (click)="money = 0">리셋</button>
  </div>
  <div class="input">
    <nz-input-number [(ngModel)]="money" [nzMin]="0" [nzStep]="10000" class="input" [nzPlaceHolder]="'지급 금액'"
      [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
  </div>
  <button class="btn" nz-button nzType="primary" (click)="deposit()" [nzLoading]="loading">지급</button>
</div>