<div class="withdraw-container">
  <div class="withdraw">아이디 : {{user.username}}</div>
  <div class="withdraw">보유금 : {{commonService.formatNumber(user.userMoney)}} 원</div>
  <div class="withdraw">차감 대상: {{withdrawUser.username}}</div>
  <div class="withdraw">
    <span>차감 대상 보유금 : {{commonService.formatNumber(withdrawUser.userMoney)}} 원</span>
    <gz-clip-board [value]="withdrawUser.userMoney"></gz-clip-board>
  </div>
  <div class="withdraw button-wrapper">
    <button nz-button nzType="default" (click)="withdrawAll()">전액</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(10000)">1만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(50000)">5만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(100000)">10만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(500000)">50만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(1000000)">1백만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(5000000)">5백만</button>
    <button nz-button nzType="danger" (click)="addRefundMoney(10000000)">1천만</button>
    <button nz-button nzType="danger" (click)="money = 0">리셋</button>
  </div>
  <div class="input">
    <nz-input-number [(ngModel)]="money" [nzMin]="0" [nzStep]="10000" class="input" [nzPlaceHolder]="'차감 금액'"
      [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
  </div>
  <button class="btn" nz-button nzType="primary" nzDanger (click)="withdraw()" [nzLoading]="loading">차감</button>
</div>