import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContentService } from '../content';

import { NavigationService } from '../navigation';
import { NoteService } from '../note';
import { AuthService } from './auth.service';

@Injectable()
export class NoteGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private noteService: NoteService,
    private navigationService: NavigationService,
  ) {}

  async canActivate(): Promise<boolean> {
    if (!this.authService.isAuthenticated()) {
      this.contentService.signOut();
      return false;
    }
    const count = await this.noteService.getImportantNoteCount();
    if (count > 0) {
      this.navigationService.goToNotePage();
      return false;
    }
    return true;
  }
}
