import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gz-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.sass'],
})
export class CopyComponent implements OnInit {
  gameId = 0;
  gameGroupId = 0;

  constructor() {}

  ngOnInit(): void {}
}
