import { Component, Input } from '@angular/core';
import { User } from '@models';
import { CommonService, ModalService, UserService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'gz-partner-deposit',
  templateUrl: './partner-deposit.component.html',
  styleUrls: ['./partner-deposit.component.sass'],
})
export class PartnerDepositComponent {
  @Input() user = new User();
  @Input() depositUser = new User();

  money = 0;
  loading = false;

  constructor(
    public commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private modal: NzModalRef,
  ) {}


  chargeAll = () => {
    this.money = Math.floor(this.user.userMoney);
  }

  addChargeMoney = (money: number) => {
    let totalMoney = this.money + (Math.floor(money || 0));

    if (totalMoney > this.user.userMoney) {
      totalMoney = Math.floor(this.user.userMoney);
      this.modalService.error('에러', `지급 가능한 최대 금액은 ${totalMoney}원 입니다.`, true);
    }

    this.money = totalMoney;
  };

  deposit = async () => {
    if (this.money <= 0) {
      this.modalService.error('에러', '지급액은 0보다 커야합니다.', true);
      return;
    }
    if (this.money > this.user.userMoney) {
      this.modalService.error('에러', '지급액이 보유금 보다 큽니다.', true);
      return;
    }
    this.loading = true;
    try {
      await this.modalService.partnerConfirm(
        '하부머니지급',
        `${this.commonService.formatNumber(this.money)}원을 지급하시겠습니까?`,
        '하부머니지급',
      );
    } catch (ex) {
      this.loading = false;
      return;
    }
    try {
      await this.userService.depositMoney(this.money, this.depositUser.userId);
      this.close();
      this.money = 0;
    } catch (ex) {
      this.modalService.error('에러', '하부머니지급 에러', true);
    }
    this.loading = false;
  };

  close = () => {
    this.modal.close();
  };

  formatComma = (value: number) => this.commonService.formatNumber(value);
  parseComma = (value: string) => this.commonService.formatToNumber(value);
}
