import { Injectable } from '@angular/core';

import * as moment from 'moment';
@Injectable()
export class CommonService {
  constructor() {}

  second2Time = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    let minutes: any = Math.floor((seconds - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;
    let time = '';

    if (hours !== 0) {
      minutes += hours * 60;
    }
    if (minutes !== 0 || time !== '') {
      minutes = minutes < 10 && time !== '' ? '0' + minutes : String(minutes);
      time += `${minutes}분`;
    }
    if (time === '') {
      time = `${seconds}초`;
    } else {
      if (seconds !== 0) {
        time += seconds < 10 ? `0${seconds}초` : `${seconds}초`;
      } else {
        time += '00초';
      }
    }
    return time;
  };

  chunkArray = (per: number, array: any[]): any[][] => {
    return array.reduce((prev, next, index) => {
      const firstIndex = Math.floor(index / per);
      if (!prev[firstIndex]) {
        prev[firstIndex] = [];
      }
      prev[firstIndex].push(next);
      return prev;
    }, []);
  };

  replaceLoginId = (loginId: string): string => {
    return loginId.replace(/^.{2}/g, '☆☆');
  };

  formatNumber = (value: string | number): string => {
    if (isNaN(Number(value))) {
      return '';
    }
    const stringValue = `${value}`;
    const list = stringValue.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };

  formatToNumber = (value: string): number => {
    return +value.replace(/[^0-9]+/g, '');
  };

  formatDateTime = (value: number): string => {
    if (!value) {
      return '';
    }
    return moment(value).utcOffset(540).format('YYYY-MM-DD HH:mm:ss');
  };

  formatDate = (value: number): string => {
    if (!value) {
      return '';
    }
    return moment(value).utcOffset(540).format('YYYY-MM-DD');
  };

  formatDateStr = (value: Date): string => {
    if (!value) {
      return '';
    }
    return moment(value).utcOffset(540).format('YYYY-MM-DD');
  };

  getZeroClass = (value: string | number) => {
    const num = Number(`${value}`.replace(/,/g, ''));
    if (isNaN(num) || num === 0) {
      return 'zero';
    }
    return '';
  };

  getColorClass = (value: string | number) => {
    const num = Number(`${value}`.replace(/,/g, ''));
    if (isNaN(num)) {
      return '';
    }
    if (num === 0) {
      return 'zero';
    }
    return num > 0 ? 'positive high-light' : 'negative high-light';
  };

  copyToClipboard = (val: string) => {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', val);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  };
}
