import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Notice, NoticeType } from '../../models/notice';

@Injectable()
export class NoticeService {
  url = '/api/notice';

  constructor(private http: HttpClient) {}

  getNotice = (noticeId: number): Promise<Notice> => {
    return this.http.get<Notice>(`${this.url}/${noticeId}`).toPromise();
  };

  getNoticeList = (
    pageIndex: number,
    pageSize: number,
    noticeType?: NoticeType,
  ): Promise<Notice[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (noticeType) {
      params = params.append('noticeType', noticeType);
    }
    return this.http
      .get<Notice[]>(`${this.url}`, { params })
      .toPromise();
  };

  getNoticeListCount = (
    pageIndex: number,
    pageSize: number,
    noticeType?: NoticeType,
  ): Promise<number> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (noticeType) {
      params = params.append('noticeType', noticeType);
    }
    return this.http
      .get<number>(`${this.url}/count`, { params })
      .toPromise();
  };
}
