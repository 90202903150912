import { Component, Input, OnInit } from '@angular/core';
import { Casino, GameGroup, User } from '@models';

@Component({
  selector: 'gz-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass'],
})
export class UserInfoComponent implements OnInit {
  @Input() partner: User;
  @Input() user: User;
  @Input() casinoList: Casino[] = [];
  @Input() gameGroupList: GameGroup[] = [];

  myUser: User;
  index = '1';
  loginId = '';

  constructor() {}

  ngOnInit(): void {
    this.loginId = `${this.user.loginId} ${this.user.username}`;
  }

  get showCasino(): boolean {
    return this.casinoList.length > 0;
  }

  get showPowerBall(): boolean {
    return this.gameGroupList.length > 0;
  }

  get showCasinoTransfer(): boolean {
    return this.casinoList.filter(casino => !casino.seamless).length > 0;
  }
}
