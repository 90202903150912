export interface Note {
  id: number;
  solutionId: number;
  type: NoteType;
  title: string;
  body: string;
  createDate: number;
  readDate: number;
  read: boolean;
  userId: number;
  username: string;
  loginId: string;
}

export enum NoteType {
  NORMAL = 'NORMAL',
  IMPORTANT = 'IMPORTANT',
}
