import { Component, Input } from '@angular/core';

@Component({
  selector: 'gz-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass'],
})
export class LayoutComponent {
  @Input() maxWidth?: number;

  get backgroundStyle() {
    return this.maxWidth ? { maxWidth: `${this.maxWidth}px` } : {};
  }
}
