import { UserDetailAccount } from '../account';
import { MoneyState } from '../money';

export class User {
  id: number;
  // TODO: 이름 중복
  name: string;
  username: string;
  solutionId: number;
  solutionName: string;
  enabled: boolean;
  memo: string;
  loginId: string;
  passwd: string;
  locked: boolean;
  parentId: number;
  loginErrorCount: number;
  phoneNumber: string;
  bank: string;
  account: string;
  owner: string;
  accountPasswd: string;
  loan: number;
  login: boolean;
  joinType: string; // TODO: ENUM
  userState: UserState;
  recommenderId: string;
  recommenderName: string;
  recommenderLoginId: number;
  recommendCode: string;
  createDate: number;
  userMoney: number;
  userPoint: number;
  betPointRate: number;
  winPointRate: number;
  lastLoginDate: number;
  lastRequestDate: number;
  apiEnabled: boolean;
  apiKey: string;
  copyEnabled: boolean;
  partnerEnabled: boolean;
  betLeader: boolean;
  pointTransferEnabled;

  userId: number;
  pointLimitTime: number;
  pointMinMoney: number;
  pointTransLogEnabled: boolean;
  manualBetEnabled: boolean;
  refundEnabled: boolean;
  partnerAccount: boolean;
  betable: boolean;
  betConfirm: boolean;
  captcha: string;
  captchaKey: string;
  betPointRateOverZeroCount: number;

  switeCoinChargeEnabled: boolean;
  switeCoinChargeUrl: string;
  switeCoinChargeContent: string;

  virtualChargeEnabled: boolean;
  virtualChargeContent: string;

  coinKey: string;
  storeId: string;

  userDepositEnabled: boolean;
  userDirectDepositEnabled: false;
  userWithdrawEnabled: boolean;
  userDirectWithdrawEnabled: false;

  casinoBetEnabled: boolean;
  slotBetEnabled: boolean;

  telegramUrl: string;
  kakaoUrl: string;

  gameList?: UserDetailAccount[];

  level: number;
  expand?: boolean;
  childrenExpand?: boolean;

  total: number;
  totalCharge: number;
  totalRefund: number;

  totalPartner: number;
  totalPartnerCharge: number;
  totalPartnerRefund: number;

  totalChildCharge: number;
  totalChildRefund: number;
  totalChild: number;

  totalAdminCharge: number;
  totalAdminRefund: number;
  totalAdmin: number;

  realTotalCharge: number;
  realTotalRefund: number;
  realTotal: number;

  userChangePasswordEnabled: boolean;
  pointTransferLogEnabled: boolean;
}

export interface CreateUser {
  solutionId: number;
  name: string;
  loginId: string;
  passwd: string;
  parentId?: number;
  bank: string;
  account: string;
  owner: string;
  accountPasswd: string;
  userMoney: number;
  betPointRate: number;
  winPointRate: number;
}

export enum UserSearchType {
  LOGIN_ID = 'loginId',
  USER_NAME = 'username',
  ACCOUNT = 'account',
  PHONE_NUMBER = 'phoneNumber',
  OWNER = 'owner',
}

export enum UserState {
  REQUEST = 'REQUEST',
  WAIT = 'WAIT',
  NORMAL = 'NORMAL',
  DISABLED = 'DISABLED',
}

export enum RankingType {
  BETTING_PROFIT_AND_LOSS = 'BETTING_PROFIT_AND_LOSS',
  POINT = 'POINT',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export interface UserRanking {
  totalProfitAndLoss: number;
  totalBetMoney: number;
  totalWinMoney: number;
  userId: number;
  username: string;
  totalAmount: number;
}

export interface UserRankingBettingTotalMoney {
  totalProfitAndLoss: number;
  totalBetMoney: number;
  totalWinMoney: number;
}

export enum ChargeType {
  USER = 'USER',
  ADMIN = 'ADMIN',
  CHARGE_ALL = 'CHARGE_ALL',
  USER_TRANS = 'USER_TRANS',
  ADMIN_TRANS = 'ADMIN_TRANS',
  TRANS_ALL = 'TRANS_ALL',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  BONUS = 'BONUS',
}

export interface UserCharge {
  id: number;
  solutionId: number;
  topId: number;
  topLoginId: string;
  topName: string;
  parentId: number;
  parentLoginId: string;
  parentName: string;
  userId: number;
  loginId: string;
  username: string;
  requestDate: number;
  amount: number;
  resultMoney: number;
  bank: string;
  owner: string;
  account: string;
  state: MoneyState;
  chargeType: UpdateType;
  memo: string;
  processorId: number;
  processorName: string;
  processDate: number;
}

export enum UpdateType {
  ADMIN = 'ADMIN',
  USER = 'USER',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export interface CopyUser {
  targetLoginId: string;
  targetSolutionId: number;
  copyRate: number;
  copyLimit: number;
  copyEnabled: boolean;
  copyReverse: boolean;
}

export class CasinoUserMoneyInfo {
  casinoMoney: number;
  userMoney: number;
  userPoint: number;
}

export type PointType =
  | 'BET'
  | 'WIN'
  | 'LOSE'
  | 'CANCEL'
  | 'CANCEL_TIE'
  | 'TRANS'
  | 'TRANS_CANCEL'
  | 'PARTNER_BET'
  | 'PARTNER_WIN'
  | 'ADMIN_CHARGE'
  | 'ADMIN_REFUND'
  | 'BONUS';
export type PointSubType = 'POWER_BALL' | 'CASINO' | 'SLOT' | 'MANUAL';
export type RequesterType = 'USER' | 'ADMIN' | 'SYSTEM';

export type UserPointLog = {
  logId: number;
  pointType: PointType;
  pointSubType: PointSubType;
  requesterType: RequesterType;
  beforePoint: number;
  amount: number;
  afterPoint: number;
  gameName: string;
  raceTitle: string;
  gameRound: string;
  createdAt: Date;
  betterLoginId: string;
  betterName: string;
};

export type BalanceType =
  | 'BET'
  | 'WIN'
  | 'CHARGE'
  | 'REFUND'
  | 'CANCEL_REFUND'
  | 'TRANS'
  | 'TRANSFER'
  | 'PAYBACK'
  | 'CANCEL'
  | 'EXCEED_WIN_LIMIT'
  | 'PARTNER_CHARGE'
  | 'PARTNER_REFUND';
export type BalanceSubType = 'POWER_BALL' | 'CASINO' | 'SLOT' | 'MANUAL';

export type UserBalanceLog = {
  logId: number;
  balanceType: BalanceType;
  balanceSubType: BalanceSubType;
  requesterType: RequesterType;
  beforeBalance: number;
  amount: number;
  afterBalance: number;
  gameName: string;
  raceTitle: string;
  gameRound: string;
  createdAt: Date;
};
