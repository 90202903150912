<gz-layout [maxWidth]="1200">
  <div class="charge-frame charge-header-txt">
    입금 안내
  </div>
  <div *ngIf="switeCoinChargeEnabled" class="charge-notice-wrapper charge-frame">
    <pre class="description">{{switeCoinChargeContent}}</pre>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="primary" class="btn" (click)="onAccountNumberConfirm()"
        [nzLoading]="accountBtnLoading">입금하기</button>
    </div>
  </div>
  <div *ngIf="user && !switeCoinChargeEnabled" class="charge-notice-wrapper charge-frame">
    <pre class="description">{{noticeContent}}</pre>
    <div style="width: 100%; text-align: center; margin-bottom: 10px;">
      <button nz-button nzType="primary" class="btn" (click)="onAccountNumberConfirm()"
        [nzLoading]="accountBtnLoading">입금 계좌번호
        확인</button>
    </div>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="primary" class="btn" (click)="onClickCreateInquiry()"
        [nzLoading]="accountBtnLoading">1:1 계좌문의하기</button>
    </div>
  </div>
  <div class="charge-frame charge-header-txt">
    입금 신청
  </div>
  <div class="charge-notice-wrapper charge-frame" style="display: flex; flex-direction: column; align-items: center;">
    <div>
      예금주: {{owner}}
    </div>
    <div *ngIf="bonusChargeList.length > 0" style="margin-top: 10px;" class="button-wrapper">
      <nz-radio-group [(ngModel)]="bonusChargeId">
        <label class="radio-label" *ngFor="let bonusCharge of bonusChargeList" [nzValue]="bonusCharge.id" nz-radio>
          {{bonusCharge.title}} {{bonusCharge.description}}
        </label>
      </nz-radio-group>
    </div>
    <div style="margin-top: 10px;" class="button-wrapper">
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(10000)">1만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(50000)">5만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(100000)">10만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(500000)">50만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(1000000)">1백만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(5000000)">5백만</button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(10000000)">1천만</button>
      <button nz-button class="charge-btn" nzType="primary" (click)="chargeMoney = 0">리셋</button>
    </div>
    <div class="button-wrapper" style="margin-top: 10px;">
      <nz-input-number [(ngModel)]="chargeMoney" [nzMin]="0" [nzStep]="10000" class="charge-input"
        [nzPlaceHolder]="'입금 요청 금액'" [nzFormatter]="formatComma" [nzParser]="parseComma">
      </nz-input-number>
      <button nz-button nzType="primary" [disabled]="disabled" [nzLoading]="chargeBtnLoading"
        (click)="onChargeBtnClick()">입금신청</button>
    </div>
  </div>
  <div class="charge-frame charge-header-txt" style="display: flex">
    <div style="flex: 1; text-align: left;">
      입금 이력
    </div>
    <div style="flex: 1; text-align: right;">
      <button nz-button nzType="primary" [nzLoading]="chargeBtnLoading" nzDanger (click)="deleteWholeCharge()">전체
        삭제</button>
    </div>
  </div>
  <div class="charge-frame">
    <nz-table nzShowSizeChanger [nzData]="chargeLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>금액</th>
          <th>예금주</th>
          <th>상태</th>
          <th>요청일시</th>
          <th>처리일시</th>
          <th>삭제</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of chargeLists">
          <td nzBreakWord>{{ formatComma(data.amount) }}원</td>
          <td nzBreakWord>{{ data.owner }}</td>
          <td nzBreakWord>
            <div [ngClass]="moneyService.moneyStateToClass(data.state)">
              {{ moneyService.moneyStateToStr(data.state) }}
            </div>
          </td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.requestDate) }}</td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.processDate) }}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="삭제하시겠습니까?" (nzOnConfirm)="deleteRow(data.id)"
              nzDanger>삭제</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>