import { Component, Input, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService, PartnerService, UserService } from '@services';
import { TwoDigitNumberRegex } from '@regex';
import { Casino, CasinoGame, User } from '@models';

@Component({
  selector: 'gz-casino-user-point-rate-form',
  templateUrl: './casino-user-point-rate-form.component.html',
  styleUrls: ['./casino-user-point-rate-form.component.sass'],
})
export class CasinoUserPointRateFormComponent implements OnInit {
  @Input() casinoList: Casino[] = [];
  @Input() user: User;

  validateForm!: FormGroup;
  loading = false;

  formLoaded = false;

  gameList: CasinoGame[] = [];
  disabled = true;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private partnerService: PartnerService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    this.clearForm();
    await this.checkDisabled();
    this.loadPoint();
  }

  clearForm = () => {
    this.validateForm = this.fb.group({
      gameList: this.fb.array([]),
    });
  };

  checkDisabled = async () => {
    const user = await this.userService.getUserInfo();
    if (user.userId === this.user.userId) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  };

  loadPoint = async () => {
    const maxBetRate = 10;
    this.gameList = await this.partnerService.getCasinoPartnerPointRate(
      this.user.userId,
      this.casinoId,
    );
    this.validateForm = this.fb.group({
      gameList: this.fb.array(
        this.gameList.map(game =>
          this.fb.group({
            settingId: [game.settingId, Validators.required],
            settingName: [game.settingName, Validators.required],
            gameType: [game.gameType, Validators.required],
            betPointRate: [
              { value: game.betPointRate, disabled: this.disabled },
              [
                Validators.required,
                Validators.min(0),
                Validators.max(maxBetRate),
                Validators.pattern(TwoDigitNumberRegex),
              ],
            ],
            losePointRate: [
              { value: game.losePointRate, disabled: this.disabled },
              [
                Validators.required,
                Validators.min(0),
                Validators.max(maxBetRate),
                Validators.pattern(TwoDigitNumberRegex),
              ],
            ],
            winPointRate: [
              { value: game.winPointRate, disabled: this.disabled },
              [
                Validators.required,
                Validators.min(0),
                Validators.max(maxBetRate),
                Validators.pattern(TwoDigitNumberRegex),
              ],
            ],
          }),
        ),
      ),
    });
  };

  submitForm = async (): Promise<void> => {
    if (this.disabled) {
      this.modalService.error('수정 에러', '본인의 수수료는 수정 불가합니다.', true);
      return;
    }
    this.loading = true;
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      try {
        const { gameList } = this.validateForm.value;
        await this.partnerService.updateCasinoPartnerPointRate(
          this.user.userId,
          this.casinoId,
          gameList,
        );
        this.modalService.success('성공', '수정 완료', true);
      } catch (ex) {}
    }
    this.loading = false;
  };

  get casinoId() {
    if (this.casinoList.length > 0) {
      return this.casinoList[0].casinoId;
    }
    return 0;
  }
}
