<gz-layout [maxWidth]="1200">
  <div class="info-frame">
    <div nz-row class="margin">
      <div nz-col nzSpan="24">
        <div>
          <input type="password" nz-input placeholder="현재 비밀번호" [(ngModel)]="passwd" class="input" />
        </div>
        <div>
          <input type="password" nz-input placeholder="새 비밀번호" [(ngModel)]="newPasswd" class="input" />
        </div>
        <div>
          <input type="password" nz-input placeholder="새 비밀번호 확인" [(ngModel)]="reNewPasswd" class="input" />
        </div>
      </div>
    </div>
    <div class="align-center">
      <button style="margin-top: 20px;" nz-button nzType="primary" class="btn" (click)="onChangePasswd()">수정</button>
    </div>
  </div>
</gz-layout>