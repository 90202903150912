export interface Notice {
  id: number;
  solutionId: number;
  title: string;
  body: string;
  noticeType: NoticeType;
  enabled: boolean;
  noticeId: number;
  createDate: number;
}

export enum NoticeType {
  NORMAL = 'NORMAL',
  POPUP = 'POPUP',
}
