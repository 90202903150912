import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { User } from '@models';
import { passwordRegex } from '@regex';
import { CommonService, ContentService, ModalService, UserService } from '@services';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gz-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.sass'],
})
export class InfoComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();

  passwd = '';
  newPasswd = '';
  reNewPasswd = '';

  loading = false;

  user: User;
  subs: Subscription[] = [];

  userPoint = 0;
  userMoney = 0;

  constructor(
    public commonService: CommonService,
    private router: Router,
    private modalService: ModalService,
    private userService: UserService,
    private contentService: ContentService,
  ) {}

  ngOnInit(): void {
    this.loadUserInfo();
    this.subs.push(
      this.contentService.userChange$.subscribe(() => {
        this.loadUserInfo();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {});
  }

  loadUserInfo = async () => {
    try {
      this.user = await this.userService.getUserInfo();
      if (this.user) {
        this.userMoney = this.user.userMoney;
        this.userPoint = this.user.userPoint;
      }
    } catch (ex) {}
  };

  onChangePasswd = async () => {
    if (!this.user.userChangePasswordEnabled) {
      return;
    }
    this.loading = true;
    try {
      if (this.passwd.length === 0) {
        this.modalService.warning('경고', '비밀번호를 입력해주세요.');
        return;
      }
      if (this.newPasswd.length === 0) {
        this.modalService.warning('경고', '새 비밀번호를 입력해주세요.');
        return;
      }
      if (this.reNewPasswd.length === 0) {
        this.modalService.warning('경고', '새 비밀번호 확인을 입력해주세요.');
        return;
      }
      if (this.newPasswd.length < 4 || this.newPasswd.length > 20) {
        this.modalService.warning('경고', '비밀번호는 4-20자 영어,숫자,특수문자만 가능합니다!');
        return;
      }
      const result = passwordRegex.test(this.newPasswd);
      if (!result) {
        this.modalService.warning('경고', '비밀번호는 4-20자 영어,숫자,특수문자만 가능합니다!');
        return;
      }
      if (this.reNewPasswd !== this.newPasswd) {
        this.modalService.warning('경고', '새 비밀번호와 확인 비밀번호가 다릅니다.');
        return;
      }
      await this.userService.changePassword(this.passwd, this.reNewPasswd);
      this.modalService.success('성공', '비밀번호 변경 성공!');
    } catch (ex) {}
    this.loading = false;
  };

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.map(sub => sub.unsubscribe());
  }
}
