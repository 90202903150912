import { Component, Input, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

import { BettingLog, GameResultType } from '@models';

import { GameService, CommonService, NavigationService, ModalService } from '@services';

@Component({
  selector: 'gz-power-ball-betting-log',
  templateUrl: './power-ball-betting-log.component.html',
  styleUrls: ['./power-ball-betting-log.component.sass'],
})
export class PowerBallBettingLogComponent implements OnInit {
  @Input() gameGroupId = '0';
  @Input() gameId = '0';
  selectedGameId = '0';

  date: Date = new Date();

  pageSize = 20;
  pageIdx = 1;
  totalCount = 0;
  loading = false;

  bettingLogList: BettingLog[] = [];

  constructor(
    public commonService: CommonService,
    public gameService: GameService,
    private navigationService: NavigationService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.loadBettingLogList();
  }

  loadBettingLogList = async () => {
    this.loading = true;
    const gameId = Number(this.gameId);
    const gameGroupId = Number(this.gameGroupId);

    const promises = [];
    promises.push(this.gameService.getTotalCount(gameGroupId, gameId, this.date));
    promises.push(
      this.gameService.getBettingLogList(
        gameGroupId,
        gameId,
        this.pageIdx - 1,
        this.pageSize,
        this.date,
      ),
    );
    const result = await Promise.all(promises);
    this.totalCount = result[0];
    let bettingLogList = result[1];
    bettingLogList = bettingLogList.map(bettingLog => {
      bettingLog.raceTitle = this.gameService.getRaceTitle(
        bettingLog.gameId,
        bettingLog.raceTitle,
        bettingLog.dailyNo,
      );
      return bettingLog;
    });
    this.bettingLogList = bettingLogList;
    this.loading = false;
  };

  onHideAllBettingLogBtnClick = async () => {
    try {
      await this.modalService.confirm('경고', '배팅로그를 삭제하시겠습니까?');
      const gameId = Number(this.selectedGameId);
      const gameGroupId = Number(this.gameGroupId);
      await this.gameService.updateBetLogVisible(false, gameGroupId, gameId);
      await this.loadBettingLogList();
    } catch (ex) {}
  };

  onSearchBettingLog = async () => {
    this.pageIdx = 1;
    this.selectedGameId = this.gameId;
    await this.loadBettingLogList();
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    await this.loadBettingLogList();
  };

  goBack = () => {
    this.navigationService.goBack();
  };

  gameResultTypeToClass = (resultType: GameResultType) => {
    return this.gameService.gameResultTypeToClass(resultType);
  };
}
