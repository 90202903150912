<div style="margin-top: 15px;" class="user-create-wrapper">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <div class="flex flex-wrap">
      <nz-form-item class="flex1">
        <nz-form-control class="form-control-pad">
          <span class="header-txt">리더 아이디</span>
          <nz-input-group>
            <input type="text" nz-input formControlName="targetLoginId" class="input" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="flex1">
      </nz-form-item>
    </div>
    <div class="flex flex-wrap">
      <nz-form-item class="flex1">
        <nz-form-control nzErrorTip="0-1000 사이의 숫자만 가능합니다." class="form-control-pad">
          <span class="header-txt">비율</span>
          <nz-input-group class="flex" nzSuffix="%">
            <input type="number" [min]="0" [max]="100" nz-input class="input" formControlName="copyRate" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="flex1">
        <nz-form-control class="form-control-pad">
          <span class="header-txt">상한가</span>
          <span class="info-txt">*0이면 무제한입니다.</span>
          <nz-input-group class="flex">
            <nz-input-number [nzMin]="0" [nzStep]="10000" class="input" style="width: 100%;" formControlName="copyLimit"
              [nzFormatter]="formatComma" [nzParser]="parseComma">
            </nz-input-number>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-row>
      <nz-form-item nz-col nzSpan="12">
        <nz-form-control class="form-control-pad">
          <span class="header-txt">따라하기</span>
          <nz-input-group>
            <nz-switch formControlName="copyEnabled" nzCheckedChildren="사용" nzUnCheckedChildren="미사용"></nz-switch>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="showReverseCopy" nz-col nzSpan="12">
        <nz-form-control class="form-control-pad">
          <span class="header-txt">거꾸로 따라하기</span>
          <nz-input-group>
            <nz-switch formControlName="copyReverse" nzCheckedChildren="사용" nzUnCheckedChildren="미사용"></nz-switch>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="align-center">
      <button nz-button nzBlock [nzType]="'primary'" [nzLoading]="loading" [disabled]="!validateForm.valid">저장</button>
    </div>
  </form>
</div>