import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Race } from '@models';

@Injectable()
export class RaceService {
  constructor(private http: HttpClient) {}

  url = '/api/gameGroup';
  timeUrl = '/auth/time/sync';

  getPreviousRace = (gameGroupId: number, preRaceCnt: number): Promise<Race> => {
    return this.http
      .get<Race>(`${this.url}/${gameGroupId}/race/previous?preRaceCnt=${preRaceCnt}`)
      .toPromise();
  };

  getCurrentRace = async (gameGroupId: number): Promise<Race> => {
    return this.http.get<Race>(`${this.url}/${gameGroupId}/race/current`).toPromise();
  };

  getServerTime = async (): Promise<number> => {
    return this.http
      .get<number>(`${this.timeUrl}`, { headers: { ignoreToken: 'true' } })
      .toPromise();
  };
}
