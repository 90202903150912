import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoneyType } from '@models';

@Injectable()
export class SolutionService {
  private url = `/api/solution`;

  constructor(private http: HttpClient) {}

  getSolutionNotice = async (moneyType: MoneyType): Promise<string> => {
    switch (moneyType) {
      case MoneyType.CHARGE:
        return this.getSolutionChargeNotice();
      case MoneyType.REFUND:
        return this.getSolutionRefundNotice();
      case MoneyType.POINT:
        return this.getSolutionPointNotice();
    }
  };

  getSolutionChargeNotice = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/notice/charge`).toPromise();
  };

  getSolutionRefundNotice = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/notice/refund`).toPromise();
  };

  getSolutionPointNotice = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/notice/point`).toPromise();
  };

  getSolutionAccountNumber = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/accountNo`).toPromise();
  };
}
