import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type GameSelectTabType = 'CASINO' | 'SLOT' | 'MINI_GAME' | 'NONE';

@Component({
  selector: 'gz-game-select-tab',
  templateUrl: './game-select-tab.component.html',
  styleUrls: ['./game-select-tab.component.sass'],
})
export class GameSelectTabComponent {
  @Input() casinoBetEnabled = false;
  @Input() slotBetEnabled = false;
  @Input() powerBallBetEnabled = false;

  @Input() tabType: GameSelectTabType = 'NONE';

  @Output() tabTypeChange: EventEmitter<GameSelectTabType> = new EventEmitter();

  btnClass = (tabType: GameSelectTabType) => {
    return this.tabType === tabType ? ['toggle-btn', 'active'] : ['toggle-btn'];
  };

  onTabClick = (tabType: GameSelectTabType) => {
    this.tabTypeChange.emit(tabType);
  };
}
