import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CommonService, UserService } from '@services';
import { PointType, UserPointLog, PageModel, User } from '@models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gz-user-point-log',
  templateUrl: './user-point-log.component.html',
  styleUrls: ['./user-point-log.component.sass'],
})
export class UserPointLogComponent implements OnInit, OnDestroy {
  @Input() user = new User();

  pageNumber = 1;
  pageSize = 40;
  totalCount = 0;

  loading = false;
  tableLoading = false;

  page: PageModel<UserPointLog> = {
    pageIndex: 0,
    totalPageCount: 0,
    totalDataCount: 0,
    pageSize: 0,
    data: [],
  };

  pointType: PointType | 'ALL' = 'ALL';

  dataLoaded = false;

  date = new Date();
  subs: Subscription[] = [];
  sub: Subscription;

  constructor(public commonService: CommonService, public userService: UserService) {}

  async ngOnInit(): Promise<void> {
    await this.loadPointLog();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageNumber = pageIdx;
    await this.loadPointLog();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadPointLog();
  };

  onSearchBtnClick = async () => {
    this.tableLoading = true;
    try {
      await this.loadPointLog();
    } catch (ex) {}
    this.tableLoading = false;
  };

  loadPointLog = async () => {
    this.loading = true;
    this.tableLoading = true;
    this.sub && this.sub.unsubscribe();

    this.sub = this.userService
      .getUserPointList(
        this.pageNumber - 1,
        this.pageSize,
        this.user.userId,
        this.pointType,
        this.date,
      )
      .subscribe(
        page => {
          this.page = page;
          this.totalCount = this.page.totalDataCount;
          this.dataLoaded = true;
          this.loading = false;
          this.tableLoading = false;
        },
        _ => {
          this.loading = false;
          this.tableLoading = false;
        },
      );
  };

  betterInfoToStr = (point: UserPointLog) => {
    if (!point.betterLoginId) {
      return '-';
    }
    return `${point.betterLoginId} (${point.betterName})`;
  };

  gameNameToStr = (point: UserPointLog) => {
    if (point.pointSubType === 'MANUAL') {
      return '-';
    }
    return point.gameName;
  };

  gameRoundToStr = (point: UserPointLog) => {
    if (point.pointSubType === 'POWER_BALL') {
      return point.raceTitle;
    } else if (point.pointSubType === 'CASINO' || point.pointSubType === 'SLOT') {
      return point.gameRound;
    }
    return '-';
  };

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
    this.subs.map(sub => sub && sub.unsubscribe());
  }
}
