import { Component, Input, OnInit } from '@angular/core';
import { CasinoGame } from '@models';
import { CasinoService, NoteService } from '@services';

@Component({
  selector: 'gz-casino-game-list',
  templateUrl: './casino-game-list.component.html',
  styleUrls: ['./casino-game-list.component.sass'],
})
export class CasinoGameListComponent implements OnInit {
  @Input() casinoId;
  @Input() providerCode;
  gameList: CasinoGame[] = [];

  isLoading = true;
  searchGameName = '';

  constructor(private casinoService: CasinoService, private noteService: NoteService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.casinoService.getCasinoGameList(this.casinoId, this.providerCode).then(
      gameList => {
        this.gameList = gameList;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  onClickGame = async (game: CasinoGame) => {
    const hasImportantNote = await this.noteService.checkImportantNote();
    if (!hasImportantNote) {
      return;
    }
    const slotUrl = await this.casinoService.getCasinoGameUrl(
      this.casinoId,
      game.vendor,
      game.gameCode,
    );
    this.casinoService.openGameWindow(slotUrl);
  };

  get filteredGameList() {
    if (this.searchGameName.length > 0) {
      return this.gameList.filter(game =>
        game.gameName.toLocaleLowerCase().includes(this.searchGameName.toLocaleLowerCase()),
      );
    }
    return this.gameList;
  }
}
