import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notice } from '@models';
import { LocalStorageService } from '@services';

@Component({
  selector: 'gz-popup-notice',
  templateUrl: './popup-notice.component.html',
  styleUrls: ['./popup-notice.component.sass'],
})
export class PopupNoticeComponent {
  @Input() notice: Notice;
  @Output() close = new EventEmitter();

  constructor(private localStorageService: LocalStorageService) {}

  closeNotice = () => {
    this.close.emit();
  };

  close12Hour = () => {
    this.localStorageService.closeNotice(this.notice.id);
    this.close.emit();
  };
}
