import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';

import { LocalStorageService, ContentService, ModalService, AuthService } from '@services';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = this.localStorageService.accessToken;

    const ignoreToken = request.headers.get('ignoreToken');

    const isPartner = request.url.includes('partner');

    if (request.url.includes('refresh')) {
      const refreshToken = this.localStorageService.refreshToken;
      request = this.addToken(request, refreshToken);
    } else if (!ignoreToken && accessToken) {
      request = this.addToken(request, accessToken);
    }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => this.handleResponse(request, event)),
      catchError((error: HttpErrorResponse) => {
        const message = error && error.error && error.error.message ? error.error.message : '';
        const resultCode =
          error && error.error && error.error.resultCode ? error.error.resultCode : 0;
        if (error.status === 403) {
          if (request.url.includes('refresh')) {
            this.isRefreshing = false;
            if (message) {
              this.modalService.error('에러', message, isPartner);
            }
            this.contentService.signOut();
            return throwError(error);
          } else {
            if (resultCode !== 0) {
              return this.handleRefreshTokenError(request, next);
            } else {
              this.contentService.signOut();
              return throwError(error);
            }
          }
        } else {
          if (message) {
            this.modalService.error('에러', message, isPartner);
          }
          if (error.status === 401) {
            this.contentService.signOut();
          }
          return throwError(error);
        }
      }),
    );
  }

  handleResponse = (request: HttpRequest<any>, event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {
      if (request.url.includes('captcha/image')) {
        return event;
      } else {
        event = event.clone({ body: event.body.response });
      }
    }
    return event;
  };

  handleRefreshTokenError = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refresh().pipe(
        switchMap((accessToken: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(request, accessToken)).pipe(
            map((event: HttpEvent<any>) => this.handleResponse(request, event)),
            catchError((error: HttpErrorResponse) => this.handleError(error)),
          );
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken)).pipe(
            map((event: HttpEvent<any>) => this.handleResponse(request, event)),
            catchError((error: HttpErrorResponse) => this.handleError(error)),
          );
        }),
      );
    }
  };

  handleError = (error: HttpErrorResponse) => {
    if (error.status === 401 || error.status === 403) {
      this.contentService.signOut();
    }
    return throwError(error);
  };

  private addToken = (request: HttpRequest<any>, token: string) => {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
