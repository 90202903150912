import { Component, OnInit } from '@angular/core';
import { Casino, GameGroup, User } from '@models';

import { CasinoService, GameService, NavigationService, UserService } from '@services';

type TabType = 'CASINO' | 'POWERBALL';

@Component({
  selector: 'gz-whole-betting-log',
  templateUrl: './whole-betting-log.component.html',
  styleUrls: ['./whole-betting-log.component.sass'],
})
export class WholeBettingLogComponent implements OnInit {
  dataLoaded = false;
  tabType: TabType = 'CASINO';
  casinoList: Casino[] = [];
  gameGroupList: GameGroup[] = [];
  user = new User();

  constructor(
    private userService: UserService,
    private casinoService: CasinoService,
    private navigationService: NavigationService,
    private gameService: GameService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData = async () => {
    const result = await Promise.all([
      this.userService.getCachedUserInfo(),
      this.casinoService.getCasinoList(),
      this.gameService.getGameGroupList(),
    ]);

    this.user = result[0];
    this.casinoList = result[1];
    this.gameGroupList = result[2];
    if (this.casinoList.length == 0) {
      this.tabType = 'POWERBALL';
    }
    this.dataLoaded = true;
  };

  goBack = () => {
    this.navigationService.goBack();
  };

  onChangeValue = (tabType: TabType) => {
    this.tabType = tabType;
  };

  tabBtnClasses = (tabType: TabType): string[] => {
    return this.tabType === tabType ? ['tab-btn'] : ['tab-btn', 'un-selected'];
  };
}
