export enum NavigationType {
  HOME = 'HOME',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
  BET = 'BET',
  INFO = 'INFO',
  INQUIRY = 'INQUIRY',
  NOTICE = 'NOTICE',
  NOTE = 'NOTE',
  PARTNER = 'PARTNER',
  POINT_TRANSFER = 'POINT_TRANSFER',
  COPY = 'COPY',
  COIN = 'COIN',
  CASINO = 'CASINO',
}

export enum SideNavigationType {
  PARTNER = 'PARTNER',
}
