import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { User } from '@models';
import { CommonService, ContentService, ModalService, MoneyService, UserService } from '@services';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gz-point-transfer',
  templateUrl: './point-transfer.component.html',
  styleUrls: ['./point-transfer.component.sass'],
})
export class PointTransferComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();
  pointLists = [];

  pointMoney: number;
  pageIdx = 1;
  pageSize = 20;
  totalCount = 0;

  loading = false;
  pointBtnLoading = false;

  user?: User;

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private commonService: CommonService,
    private contentService: ContentService,
    private userService: UserService,
    private moneyService: MoneyService,
    private modalService: ModalService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadUser();
    await this.loadPointList();

    this.subs.push(
      this.contentService.pointChange$.subscribe(async () => {
        await this.loadUser();
        await this.loadPointList();
      }),
      this.contentService.userChange$.subscribe(async (user: User) => {
        await this.setUser(user);
        await this.loadPointList();
      }),
    );

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        await this.loadUser();
      });
  }

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    await this.loadPointList();
  };

  loadUser = async () => {
    const user = await this.userService.getUserInfo();
    this.setUser(user);
    this.contentService.onUserChange(user);
  };

  setUser = async (user: User) => {
    this.user = user;

  };

  loadPointList = async () => {
    if (!this.pointTransferLogEnabled) {
      return;
    }

    this.loading = true;
    try {
      this.totalCount = await this.moneyService.getPointTotalCount();
      this.pointLists = await this.moneyService.getPointList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  onPointBtnClick = async () => {
    this.pointBtnLoading = true;
    try {
      await this.modalService.confirm(
        '입금 신청',
        `${this.formatComma(this.pointMoney)}원을 전환신청 하시겠습니까?`,
      );
      const pointData = await this.moneyService.addPoint(+this.pointMoney);
      this.totalCount += 1;
      this.pointLists = [pointData, ...this.pointLists];
      this.pointMoney = null;
      this.loadUser();
    } catch (ex) {}
    this.pointBtnLoading = false;
  };

  deleteRow = async (id: number): Promise<void> => {
    this.loading = true;
    try {
      await this.moneyService.updatePoint(id, false);
      this.totalCount -= 1;
      this.pointLists = this.pointLists.filter(d => d.id !== +id);
    } catch (ex) {}
    this.loading = false;
  };

  formatComma = (num: number) => this.commonService.formatNumber(`${num}`);
  parseComma = (value: string) => this.commonService.formatToNumber(value);

  get disabled(): boolean {
    return !this.pointMoney || this.pointMoney <= 0;
  }

  get pointMinMoney() {
    if (!this.user) {
      return 0;
    }
    return this.user.pointMinMoney;
  }

  get pointLimitTime() {
    if (!this.user) {
      return 0;
    }
    return this.user.pointLimitTime;
  }

  get userPoint() {
    if (!this.user) {
      return 0;
    }
    return this.user.userPoint;
  }

  get pointTransferLogEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.pointTransferLogEnabled;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.map(sub => sub.unsubscribe());
  }
}
