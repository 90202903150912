<div class="tab-wrapper">
  <button *ngIf="showCasino" nz-button nzType="primary" [ngClass]="tabBtnClasses(-1)" (click)="onClickBtn(-1)">
    카지노
  </button>
  <button *ngFor="let gameGroup of gameGroupList" nz-button nzType="primary" [ngClass]="tabBtnClasses(gameGroup.id)"
    (click)="onClickBtn(gameGroup.id)">
    {{gameGroup.name}}
  </button>
  <button *ngIf="showChargeAndRefund" nz-button nzType="primary" [ngClass]="tabBtnClasses(-2)" (click)="onClickBtn(-2)">
    입출금
  </button>
  <button *ngIf="showChargeAndRefund" nz-button nzType="primary" [ngClass]="tabBtnClasses(-3)" (click)="onClickBtn(-3)">
    파트너 머니이동
  </button>
</div>