import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Casino,
  CasinoGame,
  CasinoProvider,
  CasinoProviderType,
  CasinoUserMoneyInfo,
} from '@models';
import { CommonService } from '../common';

@Injectable()
export class CasinoService {
  url = '/api/casino';
  partnerUrl = '/api/partner';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getCasinoBalance = (casinoId: number): Promise<CasinoUserMoneyInfo> => {
    return this.http.get<CasinoUserMoneyInfo>(`${this.url}/${casinoId}/balance`).toPromise();
  };

  getCasinoList = (): Promise<Casino[]> => {
    return this.http.get<Casino[]>(`${this.url}`).toPromise();
  };

  getCasino = (casinoId: number): Promise<Casino> => {
    return this.http.get<Casino>(`${this.url}/${casinoId}`).toPromise();
  };

  getCasinoProviderList = (
    casinoId: number,
    providerType?: CasinoProviderType,
  ): Promise<CasinoProvider[]> => {
    let params = new HttpParams();
    if (providerType) {
      params = params.append('providerType', providerType);
    }
    return this.http
      .get<CasinoProvider[]>(`${this.url}/${casinoId}/provider`, { params })
      .toPromise();
  };

  getCasinoGameList = (casinoId: number, providerCode: string): Promise<CasinoGame[]> => {
    return this.http
      .get<CasinoGame[]>(`${this.url}/${casinoId}/game?providerCode=${providerCode}`)
      .toPromise();
  };

  getCasinoGameUrl = (casinoId: number, provider: string, gameCode: string): Promise<string> => {
    return this.http
      .get<string>(`${this.url}/${casinoId}/game/url?provider=${provider}&gameCode=${gameCode}`)
      .toPromise();
  };

  getCasinoLobbyUrl = (casinoId: number, providerId: number): Promise<string> => {
    return this.http
      .get<string>(`${this.url}/${casinoId}/provider/${providerId}/lobby/url`)
      .toPromise();
  };

  getResultUrl = (casinoId: number, transactionId: number): Promise<string> => {
    return this.http.get<string>(`${this.url}/${casinoId}/bet/${transactionId}/url`).toPromise();
  };

  addCasinoBalance = async (
    casinoId: number,
    casinoMoney: number,
  ): Promise<CasinoUserMoneyInfo> => {
    return this.http
      .post<CasinoUserMoneyInfo>(`${this.url}/${casinoId}/refund`, { casinoMoney })
      .toPromise();
  };

  subCasinoBalance = async (
    casinoId: number,
    casinoMoney: number,
  ): Promise<CasinoUserMoneyInfo> => {
    return this.http
      .post<CasinoUserMoneyInfo>(`${this.url}/${casinoId}/charge`, { casinoMoney })
      .toPromise();
  };

  getMoneyTransferLogList = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
  ): Promise<any[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());

    return this.http
      .get<any[]>(`${this.url}/${casinoId}/money/log`, { params })
      .toPromise();
  };

  getMoneyTransferLogListCount = (casinoId: number): Promise<number> => {
    return this.http.get<number>(`${this.url}/${casinoId}/money/log/count`).toPromise();
  };

  getPartnerMoneyTransferLogList = (
    userId: number,
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<any[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    return this.http
      .get<any[]>(`${this.partnerUrl}/user/${userId}/casino/${casinoId}/money/log`, { params })
      .toPromise();
  };

  getPartnerMoneyTransferLogListCount = (
    userId: number,
    casinoId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<number> => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    return this.http
      .get<number>(`${this.partnerUrl}/user/${userId}/casino/${casinoId}/money/log/count`, {
        params,
      })
      .toPromise();
  };

  updateMoneyTransferLog = (casinoId: number, logId: number): Promise<void> => {
    return this.http.patch<void>(`${this.url}/${casinoId}/money/log/${logId}`, {}).toPromise();
  };

  deleteWholeMoneyTransferLog = (casinoId: number) => {
    return this.http.patch<void>(`${this.url}/${casinoId}/money/log/all`, {}).toPromise();
  };

  openLobbyWindow = (url: string) => {
    this.openWindow(url, 'lobby');
  };

  openGameWindow = (url: string) => {
    this.openWindow(url, 'game');
  };

  openWindow = (url: string, title: string) => {
    window.open(
      url,
      title,
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,height=${
        screen.height * (2 / 3)
      },width=${screen.width * (2 / 3)}`,
    );
  };

  moneyTypeToStr = (amount: number) => {
    return amount < 0 ? '충전' : '환전';
  };

  moneyTypeToClass = (amount: number) => {
    return amount < 0 ? 'casino-charge' : 'casino-refund';
  };

  gameResultTypeToStr = (resultType: 'WIN' | 'LOSE' | 'CANCEL') => {
    switch (resultType) {
      case 'WIN':
        return '당첨';
      case 'LOSE':
        return '낙첨';
      case 'CANCEL':
        return '취소';
      default:
        return '';
    }
  };

  gameResultTypeToClass = (resultType: 'WIN' | 'LOSE' | 'CANCEL') => {
    switch (resultType) {
      case 'WIN':
        return 'game-result-win';
      case 'LOSE':
        return 'game-result-lose';
      case 'CANCEL':
        return 'game-result-refund';
      default:
        return '';
    }
  };

  providerTypeToStr = (providerType: CasinoProviderType) => {
    switch (providerType) {
      case 'CASINO':
        return '카지노';
      case 'SLOT':
        return '슬롯';
      default:
        return '알수없음';
    }
  };
}
