import { Component, Input, OnInit } from '@angular/core';
import { User, Casino, CasinoBet, CasinoSearchType } from '@models';
import { CasinoService, CommonService, GameService, PartnerService } from '@services';

@Component({
  selector: 'gz-casino-betting-log',
  templateUrl: './casino-betting-log.component.html',
  styleUrls: ['./casino-betting-log.component.sass'],
})
export class CasinoBettingLogComponent implements OnInit {
  @Input() casinoList: Casino[] = [];
  @Input() user: User;
  @Input() isBrief = false;

  pageIdx = 1;
  pageSize = 10;
  totalCount = 0;
  loading = false;

  searchBtnLoading = false;
  casinoSearchType = CasinoSearchType.ALL;
  casinoBettingLogList: CasinoBet[] = [];

  startDate: Date = new Date();

  constructor(
    public commonService: CommonService,
    public casinoService: CasinoService,
    private partnerService: PartnerService,
    public gameService: GameService,
  ) {}

  ngOnInit(): void {
    this.loadBettingLog();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageIdx = pageIdx;
    await this.loadBettingLog();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadBettingLog();
  };

  onSearchBtnClick = async () => {
    this.searchBtnLoading = true;
    try {
      await this.loadBettingLog();
    } catch (ex) {}
    this.searchBtnLoading = false;
  };

  loadBettingLog = async () => {
    this.loading = true;
    try {
      const promises = [];
      promises.push(
        this.partnerService.getCasinoBettingLogCount(
          this.user.userId,
          this.casinoId,
          this.startDate,
          this.casinoSearchType,
        ),
      );
      promises.push(
        this.partnerService.getCasinoBettingLogList(
          this.user.userId,
          this.casinoId,
          this.pageIdx - 1,
          this.pageSize,
          this.startDate,
          this.casinoSearchType,
        ),
      );
      const result = await Promise.all(promises);
      this.totalCount = result[0];
      this.casinoBettingLogList = result[1];
    } catch (ex) {}
    this.loading = false;
  };

  get casinoId() {
    if (this.casinoList.length > 0) {
      return this.casinoList[0].casinoId;
    }
    return 0;
  }
}
