<gz-layout [maxWidth]="1200">
  <div class="notice-frame">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <nz-input-group class="flex" style="align-items: center;">
            <input type="text" nz-input formControlName="title" class="input notice-title" readonly />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <nz-input-group class="flex" style="align-items: center;">
            <div [innerHTML]="body | safeHtml" class="ql-editor notice-content"></div>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div class="submit-btn-wrapper">
        <button class="submit-btn" nz-button [nzType]="'primary'">닫기</button>
      </div>
    </form>
  </div>
</gz-layout>