export interface Inquiry {
  id: number;
  solutionId: number;
  title: string;
  body: string;
  userId: number;
  loginId: string;
  username: string;
  state: InquiryState;
  replyInfo?: Response;
}

export enum InquiryState {
  REQUEST = 'REQUEST',
  WAIT = 'WAIT',
  REPLIED = 'REPLIED',
}

export interface Response {
  id: number;
  solutionId: number;
  inquiryId: number;
  title: string;
  body: string;
  clientIP: string;
  creatorId: number;
  creatorLoginId: string;
  createDate: number;
  sendMessage: boolean;
}
