import { Component, OnInit, Input } from '@angular/core';
import { CommonService, UserService, PartnerService } from '@services';
import { MoneyState, UserCharge, User } from '@models';

@Component({
  selector: 'gz-refund-log',
  templateUrl: './refund-log.component.html',
  styleUrls: ['./refund-log.component.sass'],
})
export class RefundLogComponent implements OnInit {
  @Input() user: User;

  state = MoneyState;

  pageIdx = 1;
  pageSize = 10;
  totalCount = 0;
  loading = false;

  searchBtnLoading = false;

  refundLogLists: UserCharge[] = [];

  dateRange = [new Date(), new Date()];

  constructor(
    public commonService: CommonService,
    public userService: UserService,
    private partnerService: PartnerService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadRefundList();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageIdx = pageIdx;
    await this.loadRefundList();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadRefundList();
  };

  onSearchBtnClick = async () => {
    this.searchBtnLoading = true;
    try {
      await this.loadRefundList();
    } catch (ex) {}
    this.searchBtnLoading = false;
  };

  loadRefundList = async () => {
    this.loading = true;
    try {
      const promises = [];
      promises.push(
        this.partnerService.getUserMoneyListCount(
          this.pageIdx - 1,
          this.pageSize,
          this.user.userId,
          'refund',
          this.dateRange[0],
          this.dateRange[1],
          '0',
          '0',
        ),
      );
      promises.push(
        this.partnerService.getUserMoneyList(
          this.pageIdx - 1,
          this.pageSize,
          this.user.userId,
          'refund',
          this.dateRange[0],
          this.dateRange[1],
          '0',
          '0',
        ),
      );
      const result = await Promise.all(promises);
      this.totalCount = result[0];
      this.refundLogLists = result[1];
    } catch (ex) {}
    this.loading = false;
  };
}
