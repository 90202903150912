<div class="partner-table-wrapper">
  <div class="flex">
    <div class="flex1 info">
      <div *ngIf="showMoneyAndPoint" class="text-content header-txt">
        <span>소지금 총액: </span>
        <span [ngClass]="commonService.getZeroClass(totalUserMoney)">
          {{commonService.formatNumber(totalUserMoney)}}
        </span>
      </div>
      <div *ngIf="showMoneyAndPoint" class="text-content header-txt">
        <span>수수료: </span>
        <span [ngClass]="commonService.getZeroClass(totalUserPoint)">
          {{commonService.formatNumber(totalUserPoint)}}
        </span>
      </div>
    </div>
    <div class="search-input flex1">
      <div class="search-select">
        <nz-select class="select" nzShowSearch [(ngModel)]="selectedStepLevel" (ngModelChange)="onStepSelectChange()">
          <nz-option *ngFor="let step of selectStepList" [nzLabel]="step.name" [nzValue]="step.level"></nz-option>
        </nz-select>
      </div>
      <div class="search-select">
        <nz-select class="select" nzShowSearch [(ngModel)]="searchType">
          <nz-option nzLabel="아이디" nzValue="LOGIN_ID"></nz-option>
          <nz-option nzLabel="닉네임" nzValue="USER_NAME"></nz-option>
        </nz-select>
      </div>
      <input class="search" type="text" nz-input placeholder="검색어" [(ngModel)]="searchKeyword"
        (input)="onSearchKeywordChange($event.target.value)" />
    </div>
  </div>
  <cdk-virtual-scroll-viewport [itemSize]="lineHeight" class="virtual-table" [ngStyle]="virtualTableStyle()">
    <nz-table [nzData]="tableDataList" [nzFrontPagination]="false" [nzLoading]="loading" nzBordered nzOutBordered>
      <thead>
        <tr>
          <th *ngFor="let step of tableStepList" style="min-width: 50px;">
            {{step.name}}
          </th>
          <th *ngFor="let header of headerList" style="min-width: 30px;">
            {{header}}
          </th>
          <th *ngIf="userDepositEnabled">머니지급</th>
          <th *ngIf="userWithdrawEnabled">머니차감</th>
          <th *ngIf="hasAuthToEdit">하부</th>
          <th *ngIf="hasAuthToEdit">조회</th>
        </tr>
      </thead>
      <tbody>
        <tr *cdkVirtualFor="let data of tableDataList;" [style.height.px]="lineHeight">
          <td *ngFor="let step of tableStepList">
            <div *ngIf="data.level === step.level then me else children"></div>
            <ng-template #me>
              <div class="flex user-id-wrapper">
                <span [style.color]="data.accentColor" style="cursor: pointer;" nz-popover [nzPopoverContent]="menu"
                  nzPopoverTrigger="click" nzPopoverPlacement="bottom"
                  [ngStyle]="isSearched(data.loginId, 'LOGIN_ID')">{{data.loginId}}</span>
                <span [style.color]="data.accentColor" style="cursor: pointer;" nz-popover [nzPopoverContent]="menu"
                  nzPopoverTrigger="click" nzPopoverPlacement="bottom"
                  [ngStyle]="isSearched(data.username, 'USER_NAME')">({{data.username}})</span>
              </div>
            </ng-template>
            <ng-template #children class="flex user-id-wrapper">
              <div *ngIf="data.level + 1 === step.level">
                <a nz-button nzType="link" class="normal-link" [disabled]="!hasChildren(data)"
                  (click)="onClickChildrenExpand(data)">{{expandStr(data)}}</a>
              </div>
              <div *ngIf="data.level + 1 !== step.level" class="flex user-id-wrapper">
                &nbsp;
              </div>
            </ng-template>
          </td>
          <td *ngFor="let keyItem of keyItemList" [ngClass]="tdClass">
            <container-element *ngFor="let key of keyItem">
              <container-element [ngSwitch]="key.type">
                <container-element *ngSwitchCase="'NORMAL'">
                  {{getData(data, key)}}
                </container-element>
                <container-element *ngSwitchCase="'NUMBER'" [ngClass]="getDataClass(data, key)">
                  {{commonService.formatNumber(getData(data, key))}}
                </container-element>
                <container-element *ngSwitchCase="'PERCENT'" [ngClass]="getDataClass(data, key)">
                  {{commonService.formatNumber(getData(data, key))}}%
                </container-element>
                <container-element *ngSwitchCase="'USER_STATE'">
                  <span [ngClass]="userService.userStateClass(data.userState)" style="min-width: 50px;">
                    {{userService.userStateToStr(data.userState)}}
                  </span>
                </container-element>
                <container-element *ngSwitchCase="'ACCOUNT'">
                  <div class="flex">
                    <div style="text-align: left !important;">
                      {{getAccountTitle(key)}}
                    </div>
                    <div style="flex:1; text-align: right !important" [ngClass]="getDataClass(data, key)">
                      {{commonService.formatNumber(getData(data, key))}}
                    </div>
                  </div>
                </container-element>
                <container-element *ngSwitchCase="'LOGIN'">
                  <div [ngClass]="userService.userLoginClass(data.login)">
                    {{userService.userLoginToStr(data.login)}}
                  </div>
                </container-element>
              </container-element>
            </container-element>
          </td>
          <td *ngIf="userDepositEnabled">
            <a nz-button nzType="link" (click)="onClickDepositMoney(data)"
              [disabled]="getUserDepositDisabled(data)">지급</a>
          </td>
          <td *ngIf="userWithdrawEnabled">
            <a nz-button nzDanger nzType="link" (click)="onClickWithdrawMoney(data)"
              [disabled]="getUserWithdrawDisabled(data)">차감</a>
          </td>
          <td *ngIf="hasAuthToEdit">
            <a nz-button nzType="link" (click)="onClickCreateBtn(data)">추가</a>
          </td>
          <td *ngIf="hasAuthToEdit">
            <a nz-button nzType="link" (click)="onClickEditBtn(data)">조회</a>
          </td>
          <ng-template #menu>
            <gz-pop-over [loginId]="data.loginId" [userId]="data.id"></gz-pop-over>
          </ng-template>
        </tr>
      </tbody>
    </nz-table>
  </cdk-virtual-scroll-viewport>
</div>