import { Component, OnInit, Input } from '@angular/core';
import { UserService, ModalService, PartnerService } from '@services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { loginIdRegex, NumberRegex, passwordRegex } from '@regex';

@Component({
  selector: 'gz-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.sass'],
})
export class CreateUserComponent implements OnInit {
  @Input() parentLoginId: string;

  @Input() parentId: string;

  validateForm!: FormGroup;
  loading = false;

  partnerAccount = false;
  formLoaded = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private partnerService: PartnerService,
    private modalService: ModalService,
    private modal: NzModalRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const user = await this.userService.getUserInfo();
    this.partnerAccount = user.partnerAccount;
    this.validateForm = this.fb.group({
      parentLoginId: [{ value: this.parentLoginId, disabled: this.parentLoginId ? true : false }],
      loginId: [
        null,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern(loginIdRegex),
        ],
      ],
      username: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      passwd: [
        null,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern(passwordRegex),
        ],
      ],
      account: [null],
      accountPasswd: [null],
      bank: [null],
      owner: [null],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
          Validators.pattern(NumberRegex),
        ],
      ],
      memo: [null],
    });
    this.formLoaded = true;
  }

  submitForm = async (): Promise<void> => {
    this.loading = true;
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      try {
        const user = this.validateForm.value;
        if (this.parentId) {
          user.parentId = this.parentId;
        }
        await this.partnerService.createUser(user);
        this.modalService.success('성공', '생성 성공', true);
        this.modal.destroy({ data: user });
      } catch (ex) {}
    }
    this.loading = false;
  };
}
