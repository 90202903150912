export interface GameGroup {
  id: number;
  name: string;
  enabled: boolean;
  memo: string;
  userTimeLimit: number;
  solutionId: number;
  solutionName: string;
  gameGroupId: number;
  userId: number;

  desktopMarginTop: number;
  desktopMarginLeft: number;
  desktopZoom: number;
  mobileMarginTop: number;
  mobileMarginLeft: number;
  mobileZoom: number;
  srcUrl: string;
  statisticsUrl: string;
  term: number;

  thumbnail: string;

  gameList: Game[];
}

export interface Game {
  id: number;
  dual: boolean;
  gameId: number;
  gameName: string;
  name: PowerBallGameType;
  enabled: boolean;
  memo: string;
  sortOrder: number;
  groupId: number;
  solutionId: number;
  solutionName: string;
  betPointRate: number;
  winPointRate: number;
  pickGroupList: PickGroup[];
}

export interface PickGroup {
  id: number;
  solutionId: number;
  solutionName: string;
  name: string;
  enabled: boolean;
  memo: string;
  sortOrder: number;
  pickGroupId: number;
  pickGroupName: string;
  pickList: Pick[];
}

export interface Pick {
  id: number;
  // TODO: 이름 중복
  name: string;
  pickName: string;
  solutionId: number;
  solutionName: string;
  gameId: number;
  enabled: boolean;
  memo: string;
  pickGroupSortOrder?: number;
  sortOrder: number;
  rate: number;
  betLimitMoney: number;
  pickId: number;
}

export enum PowerBallGameType {
  POWER_BALL = '파워볼',
  NORMAL_BALL_COMB = '일반볼 조합',
  POWER_BALL_COMB = '파워볼 조합',
  NORMAL_BALL_LMS = '일반볼 대중소',
  POWER_BALL_ODD_EVEN = '파워볼 홀짝조합',
  POWER_BALL_LADDER = '파워사다리',
  POWER_BALL_LADDER_COMB = '파사조합',
}

export enum PickType {
  // 파워볼
  POWER_BALL_ODD = 1,
  POWER_BALL_EVEN = 2,
  POWER_BALL_UNDER = 3,
  POWER_BALL_OVER = 4,
  NORMAL_BALL_ODD = 5,
  NORMAL_BALL_EVEN = 6,
  NORMAL_BALL_UNDER = 7,
  NORMAL_BALL_OVER = 8,
  // 일반볼 조합
  NORMAL_BALL_ODD_UNDER = 9,
  NORMAL_BALL_ODD_OVER = 10,
  NORMAL_BALL_EVEN_UNDER = 11,
  NORMAL_BALL_EVEN_OVER = 12,
  NORMAL_BALL_ODD_LARGE = 13,
  NORMAL_BALL_ODD_MIDDLE = 14,
  NORMAL_BALL_ODD_SMALL = 15,
  NORMAL_BALL_EVEN_LARGE = 16,
  NORMAL_BALL_EVEN_MIDDLE = 17,
  NORMAL_BALL_EVEN_SMALL = 18,
  // 파워볼 조합
  POWER_BALL_ODD_UNDER = 19,
  POWER_BALL_ODD_OVER = 20,
  POWER_BALL_EVEN_UNDER = 21,
  POWER_BALL_EVEN_OVER = 22,
  // 일반볼 대중소
  NORMAL_BALL_LARGE = 23,
  NORMAL_BALL_MIDDLE = 24,
  NORMAL_BALL_SMALL = 25,
  // 파워볼 홀짝조합
  POWER_BALL_ODD_NORMAL_BALL_ODD = 26,
  POWER_BALL_ODD_NORMAL_BALL_EVEN = 27,
  POWER_BALL_EVEN_NORMAL_BALL_ODD = 28,
  POWER_BALL_EVEN_NORMAL_BALL_EVEN = 29,
  // 파워 사다리
  POWER_BALL_LADDER_LEFT = 30,
  POWER_BALL_LADDER_RIGHT = 31,
  POWER_BALL_LADDER_3LINE = 32,
  POWER_BALL_LADDER_4LINE = 33,
  POWER_BALL_LADDER_ODD = 34,
  POWER_BALL_LADDER_EVEN = 35,
  // 파사 조합
  POWER_BALL_LADDER_LEFT_3LINE = 36,
  POWER_BALL_LADDER_LEFT_4LINE = 37,
  POWER_BALL_LADDER_RIGHT_3LINE = 38,
  POWER_BALL_LADDER_RIGHT_4LINE = 39,
}

export interface BettingLog {
  id: number;
  solutionId: number;
  userId: number;
  gameGroupId: number;
  gameId: number;
  raceId: number;
  raceTitle: string;
  dailyNo: number;
  createDate: number;
  pickId: number;
  pickName: string;
  state: GameResultType;
  rate: number;
  betMoney: number;
  remainMoney: number;
  winMoney: number;
  betRate: number;
  betPoint: number;
  winRate: number;
  winPoint: number;
  clientIP: string;
  visible: boolean;
}

export enum GameResultType {
  WIN = 'WIN',
  LOSE = 'LOSE',
  REFUND = 'REFUND',
  BET = 'BET',
}
