import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  refreshTokenKey = 'refreshToken';
  accessTokenKey = 'accessToken';
  logoUrlKey = 'logoUrl';

  noticeKey = 'notice';

  get refreshToken(): string {
    return localStorage.getItem(this.refreshTokenKey);
  }

  get accessToken(): string {
    return localStorage.getItem(this.accessTokenKey);
  }

  saveTokens = (refreshToken: string, accessToken: string) => {
    this.saveRefreshToken(refreshToken);
    this.saveAccessToken(accessToken);
  };

  saveRefreshToken = (refreshToken: string) => {
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  };

  saveAccessToken = (accessToken: string) => {
    localStorage.setItem(this.accessTokenKey, accessToken);
  };

  deleteTokens = () => {
    this.deleteRefreshToken();
    this.deleteAccessToken();
  };

  private deleteRefreshToken = () => {
    localStorage.removeItem(this.refreshTokenKey);
  };

  private deleteAccessToken = () => {
    localStorage.removeItem(this.accessTokenKey);
  };

  saveLogoUrl = (logoUrl: string) => {
    localStorage.setItem(this.logoUrlKey, logoUrl);
  };

  getLogoUrl = () => {
    return localStorage.getItem(this.logoUrlKey) || '';
  };

  closeNotice = (noticeId: number) => {
    localStorage.setItem(
      `${this.noticeKey}${noticeId}`,
      `${Math.floor(new Date().getTime() / 1000)}`,
    );
  };

  getNotice = (noticeId: number): number => {
    const notice = localStorage.getItem(`${this.noticeKey}${noticeId}`);
    if (!notice) {
      return null;
    }
    return Number(notice);
  };
}
