import { Component, Input , OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InquiryService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';
import { Subject } from 'rxjs';

@Component({
  selector: 'gz-detail-inquiry',
  templateUrl: './detail-inquiry.component.html',
  styleUrls: ['./detail-inquiry.component.sass'],
})
export class DetailInquiryComponent implements OnInit {
  destroyed = new Subject<any>();
  validateForm!: FormGroup;
  loading = false;

  @Input() inquiryId;
  hasReply = false;

  constructor(
    private fb: FormBuilder,
    private inquiryService: InquiryService,
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      body: [null, [Validators.required]],
      replyTitle: [null, [Validators.required]],
      replyBody: [null, [Validators.required]],
    });
    this.loadInquiryData();
  }

  loadInquiryData = async () => {
    try {
      const inquiry = await this.inquiryService.getInquiry(this.inquiryId);
      const { title, body, replyInfo } = inquiry;
      let replyTitle = '';
      let replyBody = '';
      if (replyInfo) {
        replyTitle = replyInfo.title;
        replyBody = replyInfo.body;
        this.hasReply = true;
      }
      this.validateForm.setValue({
        title,
        body,
        replyTitle,
        replyBody,
      });
    } catch (ex) {}
  };

  submitForm = () => {
    this.modal.destroy();
  };
}
