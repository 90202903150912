import { Component, Input, OnInit } from '@angular/core';
import { Casino, GameGroup, User } from '@models';

import { CasinoService, NavigationService, UserService } from '@services';

type TabType = 'CASINO' | 'POWERBALL';

@Component({
  selector: 'gz-user-betting-log',
  templateUrl: './user-betting-log.component.html',
  styleUrls: ['./user-betting-log.component.sass'],
})
export class UserBettingLogComponent implements OnInit {
  dataLoaded = false;
  tabType: TabType = 'CASINO';

  @Input() casinoList: Casino[] = [];
  @Input() gameGroupList: GameGroup[] = [];
  @Input() user = new User();

  constructor(private casinoService: CasinoService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData = async () => {
    this.casinoList = await this.casinoService.getCasinoList();
    if (this.casinoList.length == 0) {
      this.tabType = 'POWERBALL';
    }
    this.dataLoaded = true;
  };

  goBack = () => {
    this.navigationService.goBack();
  };

  onChangeValue = (tabType: TabType) => {
    this.tabType = tabType;
  };

  tabBtnClasses = (tabType: TabType): string[] => {
    return this.tabType === tabType ? ['tab-btn'] : ['tab-btn', 'un-selected'];
  };
}
