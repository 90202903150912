<div class="betting-table-wrapper">
  <div class="betting-table-info-row flex flex-wrap">
    <div class="filter-wrapper flex1 flex flex-wrap">
      <div class="filter-content">
        <gz-game-select [(gameId)]="gameId" [(gameGroupId)]="gameGroupId"></gz-game-select>
      </div>
      <div class="filter-content">
        <nz-input-group nzSearch>
          <nz-date-picker [(ngModel)]="startDate" [nzAllowClear]="false">
          </nz-date-picker>
          <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()"
            [nzLoading]="searchBtnLoading">검색</button>
        </nz-input-group>
      </div>
    </div>
  </div>
  <div class="betting-table-content-row">
    <nz-table [nzData]="bettingLogLists" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="loading"
      [nzFrontPagination]="false" [nzPageIndex]="pageIdx" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th *ngIf="!isBrief">아이디/닉네임</th>
          <th>게임</th>
          <th>회차</th>
          <th>상태</th>
          <th>배팅</th>
          <th *ngIf="!isBrief">배당</th>
          <th>배팅금액</th>
          <th *ngIf="!isBrief">배팅후머니</th>
          <th>당첨금액</th>
          <th *ngIf="!isBrief">아이피</th>
          <th>일시</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of bettingLogLists">
          <td *ngIf="!isBrief" nzBreakWord>
            <div>{{data.loginId}}</div>
            <div>({{data.username}})</div>
          </td>
          <td nzBreakWord>{{data.gameName}}</td>
          <td nzBreakWord>{{data.raceTitle}}</td>
          <td nzBreakWord>
            <div [ngClass]="gameService.gameResultTypeToClass(data.state)">
              {{gameService.gameResultTypeToStr(data.state)}}
            </div>
          </td>
          <td nzBreakWord>{{data.pickName}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{data.rate}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.betMoney)}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{commonService.formatNumber(data.remainMoney)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.winMoney)}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{data.clientIP}}</td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.createDate) }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>