export interface Casino {
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  userCasinoName: string;
  casinoUrl: string;
  apiKey: string;
  memo: string;
  logType: string;
  solutionId: number;
  solutionName: string;
  enabled: boolean;
  seamless: boolean;
  providerList: CasinoProvider[];
}

export interface CasinoProvider {
  providerId: number;
  providerCode: string;
  providerName: string;
  providerType: CasinoProviderType;
  thumbnail: string;
  memo: string;
  lobbyCode: string;
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  logType: string;
  solutionId: number;
  solutionName: string;
  userId: number;
  enabled: false;
  displayEnabled: boolean;
}

export interface CasinoGame {
  settingId: number;
  settingName: string;
  gameType: string;
  betPointRateEnabled: boolean;
  betPointRate: number;
  winPointRateEnabled: boolean;
  winPointRate: number;
  losePointRateEnabled: boolean;
  losePointRate: number;
  solutionId: number;
  solutionName: string;
  userId: number;
  username: string;
  loginId: string;
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  gameName: string;
  gameCode: string;
  vendor: string;
}

export interface CasinoUserAccount {
  id: number;
  adjustDate: string;
  solutionId: number;
  userId: number;
  username: string;
  loginId: string;
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  userCasinoName: string;
  providerId: number;
  providerCode: string;
  providerName: string;
  providerType: CasinoProviderType;
  totalCasinoBetMoney: number;
  totalCasinoWinMoney: number;
  totalCasinoBetPoint: number;
  totalCasinoBetCount: number;
  totalCasinoWinCount: number;
  totalCasinoProfit: number;
  totalSlotBetMoney: number;
  totalSlotWinMoney: number;
  totalSlotBetPoint: number;
  totalSlotBetCount: number;
  totalSlotWinCount: number;
  totalSlotProfit: number;
  totalProfit: number;
}

export interface CasinoUserDetailAccount {
  id: number;
  adjustDate: string;
  solutionId: number;
  userId: number;
  username: string;
  loginId: string;
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  userCasinoName: string;
  providerId: number;
  providerCode: string;
  providerName: string;
  providerType: CasinoProviderType;
  totalBetMoney: number;
  totalWinMoney: number;
  totalBetCount: number;
  totalBetPoint: number;
  totalWinCount: number;
  totalProfitAndLoss: number;
}

export interface CasinoBet {
  logId: number;
  solutionId: number;
  solutionName: string;
  transactionId: number;
  userId: number;
  username: string;
  loginId: string;
  casinoId: number;
  casinoName: string;
  casinoCode: string;
  userCasinoName: string;
  providerType: CasinoProviderType;

  providerCode: string;
  providerName: string;
  gameCode: number;
  gameTitle: string;
  gameRound: string;
  gameType: string;
  betMoney: number;
  winMoney: number;
  balance: number;
  betResult: 'WIN' | 'LOSE' | 'CANCEL';
  createdAt: string;
}

export interface CasinoTransaction {
  logId: number;
  logType: 'BET' | 'WIN' | 'CANCEL';
  transactionType: 'TypeA' | 'TypeB';
  casinoId: number;
  casinoName: string;
  casinoCode: string;
  solutionId: number;
  solutionName: string;
  userId: number;
  username: string;
  loginId: string;
  casinoUserId: string;
  transactionId: number;
  refererId: number | null;
  betMoney: number;
  winMoney: number;
  balance: number;
  gameType: 'slot' | 'casino';
  gameCode: string;
  gameTitle: string;
  gameRound: string | null;
  vendor: string;
  providerCode: string | null;
  providerName: string | null;
  params: string;
  gameDetail: string;
  receiveDate: string;
  processDate: string;
  createDate: string;
  processed: boolean;
  memo: string | null;
  betLogId: number;
}

export interface CasinoUserDetailAccount {
  id: number;
  adjustDate: string;
  solutionId: number;
  userId: number;
  username: string;
  loginId: string;
  casinoId: number;
  casinoCode: string;
  casinoName: string;
  userCasinoName: string;
  providerId: number;
  providerCode: string;
  providerName: string;
  providerType: CasinoProviderType;
  totalBetMoney: number;
  totalWinMoney: number;
  totalBetCount: number;
  totalBetPoint: number;
  totalWinCount: number;
  totalAdjustAmount: number;
}

export type CasinoProviderType = 'CASINO' | 'SLOT';

export type CasinoGameType = 'POWERBALL' | 'CASINO' | 'SLOT';

export enum CasinoSearchType {
  ALL = 'ALL',
  CASINO = 'CASINO',
  SLOT = 'SLOT',
}
