import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  BalanceType,
  ChargeType,
  CopyUser,
  MoneyState,
  PageModel,
  PointType,
  UpdateType,
  User,
  UserBalanceLog,
  UserPointLog,
  UserSearchType,
  UserState,
} from '@models';
import { CommonService } from '../common';

@Injectable()
export class UserService {
  url = '/api/user';
  partnerUrl = '/api/partner/user';
  copyUrl = '/api/copy';

  cachedUser: User;

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getCachedUserInfo = async (): Promise<User> => {
    if (!this.cachedUser) {
      return this.getUserInfo();
    }
    return this.cachedUser;
  };

  getUserAccountInfo = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/account`).toPromise();
  };

  changePassword = async (passwd: string, newPasswd: string): Promise<User> => {
    return this.http
      .patch<User>(`${this.url}/passwd`, { passwd, newPasswd })
      .toPromise();
  };

  getUserInfo = async (): Promise<User> => {
    const user = await this.http.get<User>(`${this.url}`).toPromise();
    this.cachedUser = user;
    return user;
  };

  getBettingMoney = (): Promise<number> => {
    return this.http.get<number>(`/api/gameGroup/0/totalBetMoney`).toPromise();
  };

  isValidAccountPassword = (password: string): Promise<boolean> => {
    let params = new HttpParams();
    params = params.append('accountPasswd', password);
    return this.http
      .get<boolean>(`${this.url}/accountPasswd`, { params })
      .toPromise();
  };

  getCopyInfo = (gameGroupId: number, gameId: number): Promise<CopyUser> => {
    return this.http
      .get<CopyUser>(`${this.copyUrl}/gameGroup/${gameGroupId}/game/${gameId}`)
      .toPromise();
  };

  updateCopyInfo = (user: CopyUser, gameGroupId: number, gameId: number): Promise<CopyUser> => {
    return this.http
      .patch<CopyUser>(`${this.copyUrl}/gameGroup/${gameGroupId}/game/${gameId}`, user)
      .toPromise();
  };

  getMegaCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/mega-coin/url`).toPromise();
  };

  getKingCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/king-coin/url`).toPromise();
  };

  getSrCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/sr-coin/url`).toPromise();
  };

  depositMoney = (amount: number, targetUserId: number) => {
    return this.http
      .post<string>(`${this.url}/money/deposit`, {
        amount,
        targetUserId,
      })
      .toPromise();
  };

  withdrawMoney = (amount: number, targetUserId: number) => {
    return this.http
      .post<string>(`${this.url}/money/withdraw`, {
        amount,
        targetUserId,
      })
      .toPromise();
  };

  getUserPointList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    pointType: PointType | 'ALL',
    startDate?: Date,
  ) => {
    let params = this.generateChargeParams(pageIndex, pageSize, startDate, startDate, '0', '0');
    if (pointType !== 'ALL') {
      params = params.append('pointType', pointType);
    }
    return this.http.get<PageModel<UserPointLog>>(`${this.partnerUrl}/${userId}/point2`, {
      params,
    });
  };

  getUserBalanceList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    balanceType: BalanceType | 'ALL',
    startDate?: Date,
  ) => {
    let params = this.generateChargeParams(pageIndex, pageSize, startDate, startDate, '0', '0');
    if (balanceType !== 'ALL') {
      params = params.append('balanceType', balanceType);
    }
    return this.http.get<PageModel<UserBalanceLog>>(`${this.partnerUrl}/${userId}/money2`, {
      params,
    });
  };

  private generateChargeParams = (
    pageIndex: number,
    pageSize: number,
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
    searchType?: UserSearchType | '0',
    searchKeyword?: string,
    exactly?: boolean,
  ): HttpParams => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (state !== '0') {
      params = params.append('state', state);
    }
    if (chargeType !== '0') {
      params = params.append('chargeType', chargeType);
    }
    if (searchType !== '0') {
      params = params.append('searchType', searchType);
    }
    if (searchKeyword) {
      params = params.append('searchKeyword', searchKeyword);
    }
    if (exactly) {
      params = params.append('exactly', `${exactly}`);
    }
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    return params;
  };

  clearCacheUser = () => {
    this.cachedUser = null;
  };

  userStateToStr = (state: UserState): string => {
    switch (state) {
      case UserState.WAIT:
        return '보류';
      case UserState.REQUEST:
        return '대기';
      case UserState.NORMAL:
        return '정상';
      case UserState.DISABLED:
        return '차단';
      default:
        return '알 수 없음';
    }
  };

  userLoginToStr = (login: boolean): string => {
    return login ? '접속' : '미접속';
  };

  chargeType2Str = (chargeType: ChargeType, memo?: string): string => {
    switch (chargeType) {
      case ChargeType.USER:
        return '회원 충전';
      case ChargeType.USER_TRANS:
        return '회원 환충';
      case ChargeType.ADMIN:
        return '관리자 충전';
      case ChargeType.ADMIN_TRANS:
        return '관리자 환충';
      case ChargeType.DEPOSIT:
        return `하부머니 지급 (${memo})`;
      case ChargeType.WITHDRAW:
        return `하부머니 차감 (${memo})`;
      case ChargeType.BONUS:
        return '충전 보너스';
      default:
        return '알수없음';
    }
  };

  updateType2Str = (updateType: UpdateType, memo?: string): string => {
    switch (updateType) {
      case UpdateType.ADMIN:
        return '관리자';
      case UpdateType.USER:
        return '회원';
      case UpdateType.DEPOSIT:
        return `하부머니 지급 (${memo})`;
      case UpdateType.WITHDRAW:
        return `하부머니 차감 (${memo})`;
      default:
        return '알수없음';
    }
  };

  moneyState2Str = (state: MoneyState): string => {
    switch (state) {
      case MoneyState.WAIT:
        return '대기';
      case MoneyState.REQUEST:
        return '신청';
      case MoneyState.CANCEL:
        return '삭제';
      case MoneyState.APPROVE:
        return '처리';
      default:
        return '알수없음';
    }
  };

  pointTypeToStr = (pointType: PointType | 'ALL') => {
    switch (pointType) {
      case 'ALL':
        return '전체';
      case 'BET':
        return '배팅';
      case 'WIN':
        return '당첨';
      case 'LOSE':
        return '낙첨';
      case 'CANCEL':
        return '취소';
      case 'CANCEL_TIE':
        return '타이 취소';
      case 'TRANS':
        return '포인트 전환';
      case 'TRANS_CANCEL':
        return '포인트 전환 취소';
      case 'PARTNER_BET':
        return '파트너 배팅';
      case 'PARTNER_WIN':
        return '파트너 당첨';
      case 'ADMIN_CHARGE':
        return '관리자 충전';
      case 'ADMIN_REFUND':
        return '관리자 차감';
      case 'BONUS':
        return '보너스';
      default:
        return '알수없음';
    }
  };

  balanceTypeToStr = (balanceType: BalanceType | 'ALL') => {
    switch (balanceType) {
      case 'ALL':
        return '전체';
      case 'BET':
        return '배팅';
      case 'WIN':
        return '당첨';
      case 'CHARGE':
        return '충전';
      case 'REFUND':
        return '환전';
      case 'CANCEL_REFUND':
        return '환전 취소';
      case 'TRANS':
        return '포인트 전환';
      case 'TRANSFER':
        return '카지노 머니 전환';
      case 'PAYBACK':
        return '환불';
      case 'CANCEL':
        return '취소';
      case 'EXCEED_WIN_LIMIT':
        return '당첨 제한';
      case 'PARTNER_CHARGE':
        return '하부 충전';
      case 'PARTNER_REFUND':
        return '하부 환전';
      default:
        return '알수없음';
    }
  };

  userStateClass = (userState: UserState): string[] => {
    switch (userState) {
      case UserState.NORMAL:
        return ['user-state', 'user-state-approve'];
      case UserState.REQUEST:
        return ['user-state', 'user-state-request'];
      case UserState.WAIT:
        return ['user-state', 'user-state-wait'];
      case UserState.DISABLED:
        return ['user-state', 'user-state-cancel'];
    }
  };

  userLoginClass = (login: boolean) => {
    return login ? 'login' : 'logout';
  };
}
