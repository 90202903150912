<div class="betting-table-wrapper">
  <div class="betting-table-info-row">
    <div class="filter-wrapper flex flex flex-wrap">
      <div class="filter-content">
        <nz-select class="info-wrapper" nzShowSearch [(ngModel)]="casinoSearchType">
          <nz-option nzLabel="전체" nzValue="ALL"></nz-option>
          <nz-option nzLabel="카지노" nzValue="CASINO"></nz-option>
          <nz-option nzLabel="슬롯" nzValue="SLOT"></nz-option>
        </nz-select>
      </div>
      <div class="filter-content">
        <nz-input-group nzSearch>
          <nz-date-picker [(ngModel)]="startDate" [nzAllowClear]="false">
          </nz-date-picker>
          <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()"
            [nzLoading]="searchBtnLoading">검색</button>
        </nz-input-group>
      </div>
    </div>
  </div>
  <div class="betting-table-content-row">
    <nz-table [nzData]="casinoBettingLogList" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="loading"
      [nzFrontPagination]="false" [nzPageIndex]="pageIdx" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th *ngIf="!isBrief">아이디/닉네임</th>
          <th>종류</th>
          <th>게임사</th>
          <th>게임이름</th>
          <th>결과</th>
          <th>배팅금액</th>
          <th>당첨금액</th>
          <th *ngIf="!isBrief">배팅후머니</th>
          <th>일시</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of casinoBettingLogList">
          <td *ngIf="!isBrief" nzBreakWord>
            <div>{{data.loginId}}</div>
            <div>({{data.username}})</div>
          </td>
          <td nzBreakWord>
            {{casinoService.providerTypeToStr(data.providerType)}}
          </td>
          <td nzBreakWord>
            {{data.providerName}}
          </td>
          <td nzBreakWord>
            {{data.gameTitle}}
          </td>
          <td nzBreakWord>
            <div [ngClass]="casinoService.gameResultTypeToClass(data.betResult)">
              {{casinoService.gameResultTypeToStr(data.betResult)}}
            </div>
          </td>
          <td nzBreakWord>
            {{commonService.formatNumber(data.betMoney)}}
          </td>
          <td nzBreakWord>
            {{commonService.formatNumber(data.winMoney)}}
          </td>
          <td *ngIf="!isBrief" nzBreakWord>
            {{commonService.formatNumber(data.balance)}}
          </td>
          <td nzBreakWord>{{commonService.formatDateTime(data.createdAt)}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>