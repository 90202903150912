import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NzMessageModule } from 'ng-zorro-antd/message';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AccountService } from './account';

import {
  AuthService,
  AuthGuardService,
  NoteGuardService,
  CasinoGuardService,
  PartnerGuardService,
} from './auth';

import { CommonService } from './common';
import { GameService } from './game';
import { LocalStorageService } from './local-storage';
import { MessageService } from './message';
import { ModalService } from './modal';
import { NavigationService } from './navigation';
import { RaceService } from './race';
import { SolutionService } from './solution';
import { MoneyService } from './money';
import { UserService } from './user';
import { ContentService } from './content';
import { NoticeService } from './notice';
import { NoteService } from './note';
import { InquiryService } from './inquiry';
import { AudioService } from './audio';
import { PartnerService } from './partner';
import { CasinoService } from './casino';

import { RequestInterceptor } from '@interceptors';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    /* NG ZORRO Service Module */
    NzMessageModule,
  ],
  declarations: [],
  exports: [BrowserModule, BrowserAnimationsModule, HttpClientModule],
  providers: [
    AccountService,
    AudioService,
    AuthService,
    AuthGuardService,
    CasinoGuardService,
    NoteGuardService,
    PartnerGuardService,
    CasinoService,
    ContentService,
    CommonService,
    GameService,
    InquiryService,
    LocalStorageService,
    MessageService,
    ModalService,
    MoneyService,
    NavigationService,
    NoteService,
    NoticeService,
    PartnerService,
    RaceService,
    SolutionService,
    UserService,
    // third party
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
})
export class ServiceModule {}
