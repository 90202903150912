<div class="point-wrapper">
  <div class="point-info-row flex">
    <div class="filter-content">
      <gz-point-select [(pointType)]="pointType"></gz-point-select>
    </div>
    <div class="filter-content">
      <nz-date-picker [(ngModel)]="date"></nz-date-picker>
      <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()" [nzLoading]="tableLoading">검색</button>
    </div>
  </div>
  <div class="balance-content-row">
    <nz-table [nzData]="page.data" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="tableLoading"
      [nzFrontPagination]="false" [nzPageIndex]="pageNumber" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th>타입</th>
          <th>배팅아이디</th>
          <th>게임</th>
          <th>회차</th>
          <th>이전 포인트</th>
          <th>포인트</th>
          <th>이후 포인트</th>
          <th>적립 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of page.data">
          <td nzBreakWord>{{userService.pointTypeToStr(data.pointType)}}</td>
          <td nzBreakWord>{{betterInfoToStr(data)}}</td>
          <td nzBreakWord>{{gameNameToStr(data)}}</td>
          <td nzBreakWord>{{gameRoundToStr(data)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.beforePoint)}}</td>
          <td nzBreakWord [ngClass]="commonService.getColorClass(data.amount)">
            {{commonService.formatNumber(data.amount)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.afterPoint)}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.createdAt)}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>