import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  CasinoPartner,
  CasinoUserAccount,
  CasinoUserDetailAccount,
  PageModel,
  User,
  UserAccount,
  UserAccountDetail,
  UserSearchType,
} from '@models';
import { CommonService } from '../common';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountService {
  url = '/api/summary';
  adjustUrl = '/api/adjust/partner';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  searchPartnerList = (
    gameGroupId: number,
    gameId: number,
    startDate: Date,
    endDate: Date,
  ): Observable<User[]> => {
    let params = new HttpParams();
    params = params
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    return this.http
      .get<User[]>(`${this.url}/partner`, { params })
      .pipe(
        map(dataList =>
          dataList.map((user, index) => {
            if (index === 0) {
              user.expand = true;
            }
            return user;
          }),
        ),
      );
  };

  searchCasinoPartnerList = (
    casinoId: number,
    startDate: Date,
    endDate: Date,
  ): Observable<CasinoPartner[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('casinoId', casinoId.toString());
    return this.http
      .get<CasinoPartner[]>(`${this.url}/partner/casino`, { params })
      .pipe(
        map(dataList =>
          dataList.map((user, index) => {
            if (index === 0) {
              user.expand = true;
            }
            return user;
          }),
        ),
      );
  };

  searchChargeAndRefundPartnerList = (startDate: Date, endDate: Date): Observable<User[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate));
    return this.http
      .get<User[]>(`${this.url}/partner/money`, { params })
      .pipe(
        map(dataList =>
          dataList.map((data, index) => {
            if (index === 0) {
              data.expand = true;
            }
            data.totalChild = (data.totalChildCharge || 0) - (data.totalChildRefund || 0);

            data.totalAdmin = (data.totalAdminCharge || 0) - (data.totalAdminRefund || 0);
            data.total = (data.totalCharge || 0) - (data.totalRefund || 0);
            data.totalPartner = (data.totalPartnerCharge || 0) - (data.totalPartnerRefund || 0);

            data.realTotalCharge = (data.totalCharge || 0) + (data.totalPartnerCharge || 0);
            data.realTotalRefund = (data.totalRefund || 0) + (data.totalPartnerRefund || 0);
            data.realTotal = (data.realTotalCharge || 0) - (data.realTotalRefund || 0);

            return data;
          }),
        ),
      );
  };

  searchChargeAndRefundPartnerListCount = (
    searchType: UserSearchType,
    searchKeyword: string,
  ): Observable<number> => {
    let params = new HttpParams();
    params = params.append('searchType', searchType).append('searchKeyword', searchKeyword);
    return this.http.get<number>(`${this.url}/partner/money/count`, { params });
  };

  searchCasinoUserAccountList = (
    pageIndex: number,
    pageSize: number,
    casinoId: number,
    startDate: Date,
    endDate: Date,
    searchType?: UserSearchType,
    searchKeyword?: string,
    sortOrder?: string,
    sortColumn?: string,
    userId?: number,
  ): Promise<PageModel<CasinoUserAccount>> => {
    let params = new HttpParams();

    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString())
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate));

    if (searchKeyword) {
      params = params.append('searchType', searchType).append('searchKeyword', searchKeyword);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    if (sortColumn) {
      params = params.append('sortColumn', sortColumn);
    }

    if (userId) {
      params = params.append('userId', userId.toString());
    }

    return this.http
      .get<PageModel<CasinoUserAccount>>(`${this.adjustUrl}/user/casino/${casinoId}`, { params })
      .toPromise();
  };

  searchCasinoUserAccountDetailList = (
    casinoId: number,
    startDate: Date,
    endDate: Date,
    userId: number,
  ): Promise<CasinoUserDetailAccount[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('userId', userId.toString());

    return this.http
      .get<CasinoUserDetailAccount[]>(`${this.adjustUrl}/user/${userId}/casino/${casinoId}`, {
        params,
      })
      .toPromise();
  };

  searchUserAccountList = (
    pageIndex: number,
    pageSize: number,
    startDate: Date,
    endDate: Date,
    searchType?: UserSearchType,
    searchKeyword?: string,
    sortOrder?: string,
    sortColumn?: string,
    userId?: number,
  ): Promise<PageModel<UserAccount>> => {
    let params = new HttpParams();

    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString())
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate));

    if (searchKeyword) {
      params = params.append('searchType', searchType).append('searchKeyword', searchKeyword);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    if (sortColumn) {
      params = params.append('sortColumn', sortColumn);
    }

    if (userId) {
      params = params.append('userId', userId.toString());
    }

    return this.http
      .get<PageModel<UserAccount>>(`${this.adjustUrl}/user/powerball`, {
        params,
      })
      .toPromise();
  };

  searchUserAccountDetailList = (
    startDate: Date,
    endDate: Date,
    userId: number,
  ): Promise<UserAccountDetail[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('userId', userId.toString());

    return this.http
      .get<UserAccountDetail[]>(`${this.adjustUrl}/user/${userId}/powerball`, {
        params,
      })
      .toPromise();
  };
}
