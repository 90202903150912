import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PointType } from '@models';
import { UserService } from '@services';

@Component({
  selector: 'gz-point-select',
  templateUrl: './point-select.component.html',
  styleUrls: ['./point-select.component.sass'],
})
export class PointSelectComponent {
  @Input() pointType: PointType | 'ALL' = 'ALL';
  @Output() pointTypeChange = new EventEmitter<PointType | 'ALL'>();

  pointTypeList: ('ALL' | PointType)[] = [
    'ALL',
    'BET',
    'WIN',
    'LOSE',
    'CANCEL',
    'CANCEL_TIE',
    'TRANS',
    'TRANS_CANCEL',
    'PARTNER_BET',
    'PARTNER_WIN',
    'ADMIN_CHARGE',
    'ADMIN_REFUND',
    'BONUS',
  ];

  constructor(public userService: UserService) {}

  onChangeValue = () => {
    this.pointTypeChange.emit(this.pointType);
  };
}
