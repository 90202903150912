import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inquiry, InquiryState } from '@models';

@Injectable()
export class InquiryService {
  constructor(private http: HttpClient) {}

  url = '/api/inquiry';

  getInquiryList = (pageIndex: number, pageSize: number): Promise<Inquiry[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    return this.http
      .get<Inquiry[]>(`${this.url}`, { params })
      .toPromise();
  };

  getInquiryListCount = (pageIndex: number, pageSize: number): Promise<number> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    return this.http
      .get<number>(`${this.url}/count`, { params })
      .toPromise();
  };

  createInquiry = (inquiry: Inquiry): Promise<Inquiry> => {
    return this.http.post<Inquiry>(`${this.url}`, inquiry).toPromise();
  };

  getInquiry = (inquiryId: number): Promise<Inquiry> => {
    return this.http.get<Inquiry>(`${this.url}/${inquiryId}`).toPromise();
  };

  deleteAllInquiry = () => {
    return this.http.delete(`${this.url}`).toPromise();
  };

  deleteInquiry = (inquiryId: number) => {
    return this.http.delete(`${this.url}/${inquiryId}`).toPromise();
  };

  inquiryStateToStr = (inquiryState: InquiryState) => {
    switch (inquiryState) {
      case InquiryState.REPLIED:
        return '응답 완료';
      case InquiryState.WAIT:
        return '대기';
      case InquiryState.REQUEST:
        return '요청';
      default:
        return '알수없음';
    }
  };
}
