<gz-layout [maxWidth]="1200">
  <div class="inquiry-notice-wrapper inquiry-frame">
    <button nz-button nzType="primary" style="border-radius: 5px;" (click)="createInquiry()">1:1 문의 작성</button>
    <button nz-button nzType="primary" style="border-radius: 5px; margin-left: 20px;" (click)="deleteAllInquiry()"
      nzDanger>전체 삭제</button>
  </div>
  <div class="inquiry-frame">
    <nz-table nzShowSizeChanger [nzData]="inquiryLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>제목</th>
          <th>문의 날짜</th>
          <th>상태</th>
          <th>응답 날짜</th>
          <th>보기</th>
          <th>삭제</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of inquiryLists">
          <td nzBreakWord>{{data.title}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.createDate)}}</td>
          <td nzBreakWord>{{inquiryService.inquiryStateToStr(data.state)}}</td>
          <td nzBreakWord>{{replyDate(data)}}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="detailInquiry(data.id)">보기</a>
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="삭제하시겠습니까?" (nzOnConfirm)="deleteRow(data.id)"
              nzDanger>삭제</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>