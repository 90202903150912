<gz-layout [maxWidth]="1200">
  <div class="notice-frame">
    <nz-table nzShowSizeChanger [nzData]="noticeLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>제목</th>
          <th>보기</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of noticeLists">
          <td nzBreakWord>{{data.title}}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="detailNotice(data.id)">보기</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>