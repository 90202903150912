import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notice } from '@models';
import { LocalStorageService } from '@services';

@Component({
  selector: 'gz-popup-notice-list',
  templateUrl: './popup-notice-list.component.html',
  styleUrls: ['./popup-notice-list.component.sass'],
})
export class PopupNoticeListComponent {
  @Input() noticeList: Notice[] = [];
  @Output() close = new EventEmitter();

  constructor(private localStorageService: LocalStorageService) {}

  get filteredNoticeList() {
    return this.noticeList.filter(this.showNotice);
  }

  showNotice = (notice: Notice) => {
    const closedAt = this.localStorageService.getNotice(notice.id);
    if (!closedAt) {
      return true;
    }
    return Math.floor(new Date().getTime() / 1000) - closedAt >= 60 * 60 * 12;
  };

  closeNotice = (notice: Notice) => {
    this.close.emit(notice);
  };

  get noticeHeight() {
    return document.documentElement.scrollHeight;
  }
}
