<div *ngIf="notice" class="notice-frame">
  <div class="title">
    {{notice.title}}
  </div>
  <div [innerHTML]="notice.body | safeHtml" class="ql-editor content"></div>
  <div class="submit-btn-wrapper">
    <button class="submit-btn2" nz-button [nzType]="'danger'" (click)="close12Hour()">12시간 동안 안보기</button>
  </div>
  <div class="submit-btn-wrapper">
    <button class="submit-btn" nz-button [nzType]="'primary'" (click)="closeNotice()">닫기</button>
  </div>
</div>