import { NzStringTemplateOutletContext } from 'ng-zorro-antd/core/outlet/string_template_outlet.directive';

export interface RefundData {
  id: number;
  amount: number;
  state: MoneyState;
  requestDate: number;
  processDate: number;
}

export interface ChargeData extends RefundData {
  owner: string;
  resultMoney: number;
}

export enum MoneyType {
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
  POINT = 'POINT',
}

export enum MoneyState {
  REQUEST = 'REQUEST',
  WAIT = 'WAIT',
  APPROVE = 'APPROVE',
  CANCEL = 'CANCEL',
}

export interface MoneyRankData {
  loginId: string;
  amount: number;
  createdAt: string;
}
