import { Component, Input } from '@angular/core';

import { User } from '@models';
import { CommonService, ModalService, UserService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'gz-partner-withdraw',
  templateUrl: './partner-withdraw.component.html',
  styleUrls: ['./partner-withdraw.component.sass'],
})
export class PartnerWithdrawComponent {
  @Input() user = new User();
  @Input() withdrawUser = new User();

  money = 0;
  loading = false;

  constructor(
    public commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private modal: NzModalRef,
  ) {}

  withdrawAll = () => {
    this.money = Math.floor(this.withdrawUser.userMoney);
  }

  addRefundMoney = (money: number) => {
    let totalMoney = this.money + (Math.floor(money || 0));

    if (totalMoney > this.withdrawUser.userMoney) {
      totalMoney = Math.floor(this.withdrawUser.userMoney);
      this.modalService.error('에러', `차감 가능한 최대 금액은 ${totalMoney}원 입니다.`, true);
    }

    this.money = totalMoney;
  };

  withdraw = async () => {
    if (this.money <= 0) {
      this.modalService.error('에러', '차감액은 0보다 커야합니다.', true);
      return;
    }
    if (this.money > this.withdrawUser.userMoney) {
      this.modalService.error('에러', '차감액이 보유금 보다 큽니다.', true);
      return;
    }
    this.loading = true;
    try {
      await this.modalService.partnerConfirm(
        '하부머니차감',
        `${this.commonService.formatNumber(this.money)}원을 차감하시겠습니까?`,
        '하부머니차감',
      );
    } catch (ex) {
      this.loading = false;
      return;
    }
    try {
      await this.userService.withdrawMoney(this.money, this.withdrawUser.userId);
      this.close();
      this.money = 0;
    } catch (ex) {
      this.modalService.error('에러', '하부머니차감 에러', true);
    }
    this.loading = false;
  };

  close = () => {
    this.modal.close();
  };

  formatComma = (value: number) => this.commonService.formatNumber(value);
  parseComma = (value: string) => this.commonService.formatToNumber(value);
}
