import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GameGroup } from '@models';
import { GameService } from '@services';

interface GameSelect {
  label: string;
  value: string;
}

@Component({
  selector: 'gz-game-select',
  templateUrl: './game-select.component.html',
  styleUrls: ['./game-select.component.sass'],
})
export class GameSelectComponent implements OnInit, OnChanges {
  @Input() showWhole = true;

  @Input() gameGroupId: string;
  @Input() gameId: string;
  @Input() gameName: string;

  @Output() gameGroupIdChange = new EventEmitter<string>();
  @Output() gameIdChange = new EventEmitter<string>();
  @Output() gameNameChange = new EventEmitter<string>();

  gameSelectId: string;
  gameGroupList: GameGroup[] = [];
  selectGameList: GameSelect[] = [];

  dataLoaded = false;

  constructor(private gameService: GameService) {}

  async ngOnInit(): Promise<void> {
    this.gameGroupList = await this.gameService.getGameGroupList();
    this.selectGameList = this.gameGroupList.reduce((prev, next) => {
      return [
        ...prev,
        ...next.gameList.map(game => {
          const id = `${next.id}:${game.id}`;
          return this.generateGameSelect(game.name, id);
        }),
      ];
    }, []);
    this.initGameSelectId();
    this.setValue();
    this.dataLoaded = true;
  }

  ngOnChanges(): void {
    if (this.dataLoaded) {
      this.initGameSelectId();
    }
  }

  initGameSelectId = () => {
    if (this.gameGroupId === undefined || this.gameId === undefined) {
      if (this.selectGameList.length > 0) {
        this.gameSelectId = this.selectGameList[0].value;
      }
    } else if (this.gameGroupId === '0' || this.gameId === '0') {
      this.gameSelectId = '0';
    } else {
      this.gameSelectId = `${this.gameGroupId}:${this.gameId}`;
    }
  };

  setValue = () => {
    if (this.gameSelectId === '0') {
      this.gameGroupId = '0';
      this.gameId = '0';
    } else {
      const ids = this.gameSelectId.split(':');
      this.gameGroupId = ids[0];
      this.gameId = ids[1];
    }
    const gameGroup = this.gameGroupList.find(elem => elem.id === Number(this.gameGroupId));
    if (gameGroup) {
      const game = gameGroup.gameList.find(elem => elem.id === Number(this.gameId));
      this.gameName = game.gameName;
    }
  };

  onChangeValue = () => {
    this.setValue();
    this.gameGroupIdChange.emit(this.gameGroupId);
    this.gameIdChange.emit(this.gameId);
    this.gameNameChange.emit(this.gameName);
  };

  generateGameSelect = (label: string, value: string): GameSelect => {
    return { label, value };
  };
}
