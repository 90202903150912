import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NoticeService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';

import { Subject } from 'rxjs';

@Component({
  selector: 'gz-detail-notice',
  templateUrl: './detail-notice.component.html',
  styleUrls: ['./detail-notice.component.sass'],
})
export class DetailNoticeComponent implements OnInit {
  @Input() noticeId;

  destroyed = new Subject<any>();
  validateForm!: FormGroup;
  body: string;

  constructor(
    private fb: FormBuilder,
    private noticeService: NoticeService,
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
    });
    this.loadNoticeDetail();
  }

  loadNoticeDetail = async () => {
    try {
      const notice = await this.noticeService.getNotice(this.noticeId);
      const { title, body } = notice;
      this.body = body;
      this.validateForm.setValue({
        title,
      });
    } catch (ex) {}
  };

  submitForm = () => {
    this.modal.destroy();
  };
}
