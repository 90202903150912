import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ContentService,
  ModalService,
  MoneyService,
  SolutionService,
  UserService,
} from '@services';
import { CommonService } from 'src/services/common';
import { RefundData, User } from '@models';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gz-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.sass'],
})
export class RefundComponent implements OnInit, OnDestroy {
  notice = '';

  refundMoney: number;

  accountBtnLoading = false;
  refundBtnLoading = false;
  isVisible = false;

  pageIdx = 1;
  pageSize = 20;
  totalCount = 0;
  loading = true;
  account = '';

  user: User;
  accountPasswd: string;
  subs: Subscription[] = [];
  destroyed = new Subject<any>();

  refundLists: RefundData[] = [];

  constructor(
    private contentService: ContentService,
    private commonService: CommonService,
    private modalService: ModalService,
    private solutionService: SolutionService,
    private moneyService: MoneyService,
    private userService: UserService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.notice = await this.solutionService.getSolutionRefundNotice();
    this.account = await this.userService.getUserAccountInfo();
    await this.loadUserData();
    this.subs.push(
      this.contentService.refundChange$.subscribe(async () => {
        await this.loadRefundList();
        await this.loadUserData();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        this.pageIdx = 1;
        this.pageSize = 20;
        this.totalCount = 0;
        await this.loadRefundList();
        await this.loadUserData();
      });
  }

  loadUserData = async () => {
    this.user = await this.userService.getUserInfo();
    setTimeout(() => {
      this.contentService.onUserChange(this.user);
    }, 0);
  };

  onAccountNumberConfirm = async () => {
    this.accountBtnLoading = true;
    try {
      await this.modalService.confirm('출금 계좌', '출금계좌 안내를 받으시겠습니까?');
    } catch (ex) {}
    this.accountBtnLoading = false;
  };

  onRefundBtnClick = async () => {
    this.refundBtnLoading = true;
    try {
      await this.modalService.confirm(
        '출금 신청',
        `${this.formatComma(this.refundMoney)}원을 출금신청 하시겠습니까?`,
      );
      this.isVisible = true;
    } catch (ex) {
      this.refundBtnLoading = false;
    }
  };

  loadRefundList = async () => {
    this.loading = true;
    try {
      this.totalCount = await this.moneyService.getRefundTotalCount();
      this.refundLists = await this.moneyService.getRefundList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  get disabled(): boolean {
    return !this.refundMoney || this.refundMoney <= 0;
  }

  deleteWholeRefund = async () => {
    try {
      await this.modalService.deleteConfirm('전체삭제', '환전이력을 전체 삭제하시겠습니까?');
      await this.moneyService.deleteWholeRefund();
      await this.loadRefundList();
    } catch (ex) {}
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    await this.loadRefundList();
  };

  formatComma = (num: number) => this.commonService.formatNumber(`${num}`);
  parseComma = (value: string) => this.commonService.formatToNumber(value);

  handleOk = async () => {
    this.isVisible = false;
    try {
      const isValid = await this.userService.isValidAccountPassword(this.accountPasswd);
      if (isValid) {
        await this.moneyService.addRefund(+this.refundMoney);
        this.refundMoney = null;
        await this.loadRefundList();
        await this.loadUserData();
      } else {
        this.modalService.error('에러', '비밀번호가 틀렸습니다.');
      }
    } catch (ex) {}
    this.refundBtnLoading = false;
    this.accountPasswd = '';
  };

  handleCancel = () => {
    this.isVisible = false;
    this.accountPasswd = '';
    this.refundBtnLoading = false;
  };

  addRefundMoney = (money: number) => {
    if (!this.refundMoney) {
      this.refundMoney = 0;
    }
    this.refundMoney += money;
  };

  deleteRow = async (id: number): Promise<void> => {
    this.loading = true;
    try {
      await this.moneyService.updateRefund(id, false);
      this.totalCount -= 1;
      this.refundLists = this.refundLists.filter(d => d.id !== +id);
    } catch (ex) {}
    this.loading = false;
  };

  ngOnDestroy(): void {
    this.subs.map(sub => sub.unsubscribe());
    this.destroyed.next();
    this.destroyed.complete();
  }
}
