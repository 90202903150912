import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import ko from '@angular/common/locales/ko';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';

import {
  BettingLogComponent,
  CasinoBettingLogComponent,
  CasinoProviderTabComponent,
  CasinoTabComponent,
  ChargeComponent,
  CopyComponent,
  CopyFormComponent,
  CreateInquiryComponent,
  DetailInquiryComponent,
  DetailNoteComponent,
  GameGroupTabComponent,
  BalanceSelectComponent,
  GameSelectComponent,
  GameSelectTabComponent,
  PointSelectComponent,
  GameTabComponent,
  InfoComponent,
  InquiryComponent,
  LayoutComponent,
  NoteComponent,
  PartnerDepositComponent,
  PartnerWithdrawComponent,
  PointTransferComponent,
  PowerBallBettingLogComponent,
  RefundComponent,
  WholeBettingLogComponent,
  PartnerTableComponent,
  ClipBoardComponent,
} from '@components';
import {
  CasinoUserPointRateFormComponent,
  ChargeLogComponent,
  RefundLogComponent,
  UserBalanceLogComponent,
  UserBettingLogComponent,
  UserInfoComponent,
  UserPointLogComponent,
  UserPointRateComponent,
  UserPointRateFormComponent,
} from './user-info';

registerLocaleData(ko);

@NgModule({
  imports: [
    CommonModule,
    DemoNgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    DragDropModule,
  ],
  declarations: [
    // component
    BettingLogComponent,
    CasinoBettingLogComponent,
    PartnerTableComponent,
    ClipBoardComponent,
    // layout
    LayoutComponent,
    // modal
    PowerBallBettingLogComponent,
    WholeBettingLogComponent,
    CopyComponent,
    CopyFormComponent,
    InfoComponent,
    InquiryComponent,
    CreateInquiryComponent,
    DetailInquiryComponent,
    ChargeComponent,
    RefundComponent,
    NoteComponent,
    DetailNoteComponent,
    PartnerDepositComponent,
    PartnerWithdrawComponent,
    PointTransferComponent,
    // select
    BalanceSelectComponent,
    GameSelectComponent,
    PointSelectComponent,
    // tab
    CasinoTabComponent,
    CasinoProviderTabComponent,
    GameTabComponent,
    GameGroupTabComponent,
    GameSelectTabComponent,
    // user-info
    UserBettingLogComponent,
    ChargeLogComponent,
    UserBalanceLogComponent,
    UserPointLogComponent,
    UserPointRateComponent,
    UserPointRateFormComponent,
    CasinoUserPointRateFormComponent,
    RefundLogComponent,
    UserInfoComponent,
  ],
  exports: [
    //ng-zorro
    DemoNgZorroAntdModule,
    // component
    BettingLogComponent,
    CasinoBettingLogComponent,
    PartnerTableComponent,
    ClipBoardComponent,
    // layout
    LayoutComponent,
    // modal
    PowerBallBettingLogComponent,
    WholeBettingLogComponent,
    CopyComponent,
    CopyFormComponent,
    InfoComponent,
    InquiryComponent,
    CreateInquiryComponent,
    DetailInquiryComponent,
    ChargeComponent,
    RefundComponent,
    NoteComponent,
    DetailNoteComponent,
    PartnerDepositComponent,
    PartnerWithdrawComponent,
    PointTransferComponent,
    // select
    BalanceSelectComponent,
    GameSelectComponent,
    PointSelectComponent,
    // tab
    CasinoTabComponent,
    CasinoProviderTabComponent,
    GameTabComponent,
    GameGroupTabComponent,
    GameSelectTabComponent,
    // user-info
    UserBettingLogComponent,
    ChargeLogComponent,
    UserBalanceLogComponent,
    UserPointLogComponent,
    UserPointRateComponent,
    UserPointRateFormComponent,
    CasinoUserPointRateFormComponent,
    RefundLogComponent,
    UserInfoComponent,
  ],
  providers: [],
})
export class SharedModule {}
