<form *ngIf="formLoaded" nz-form [formGroup]="validateForm" class="user-create-wrapper" (ngSubmit)="submitForm()">
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control class="form-control-pad">
        <span class="header-txt">최상위 파트너</span>
        <span class="info-txt">* 비워두면 최상위 회원으로 등록</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="parentLoginId" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="은행이름을 입력해주세요!" class="form-control-pad">
        <span class="header-txt">*은행명</span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="bank" class="input" />
          <div *ngIf="!partnerAccount">관리자에게 문의하세요</div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="아이디는 4-20자 영어 숫자조합만 가능합니다!" class="form-control-pad">
        <span class="header-txt">*아이디</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="loginId" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="계좌번호를 입력해주세요!" class="form-control-pad">
        <span class="header-txt">*계좌번호</span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="account" class="input" />
          <div *ngIf="!partnerAccount">관리자에게 문의하세요</div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="닉네임은 2-20자 여야합니다!" class="form-control-pad">
        <span class="header-txt">*닉네임</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="username" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="예금주를 입력해주세요!" class="form-control-pad">
        <span class="header-txt">*예금주</span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="owner" class="input" />
          <div *ngIf="!partnerAccount">관리자에게 문의하세요</div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="비밀번호는 4-20자 영어,숫자,특수문자만 가능합니다!" class="form-control-pad">
        <span class="header-txt">*비밀번호</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="passwd" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="연락처를 입력해주세요!" class="form-control-pad">
        <span class="header-txt">*연락처 -없이 입력</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="phoneNumber" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="환전 비밀번호를 입력해주세요." class="form-control-pad">
        <span class="header-txt">*환전 비밀번호</span>
        <nz-input-group>
          <input type="text" nz-input formControlName="accountPasswd" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
    </nz-form-item>
  </div>
  <div style="text-align: center; padding: 0px 20px;">
    <button nz-button nzBlock [nzType]="'primary'" [nzLoading]="loading">회원추가</button>
  </div>
</form>