<div class="charge-table-wrapper">
  <div class="charge-table-info-row flex flex-wrap">
    <div class="filter-wrapper flex1"></div>
    <div class="info-wrapper flex1 flex flex-wrap">
      <div class="filter-content">
        <nz-select nzShowSearch [(ngModel)]="chargeType" style="width: 120px;">
          <nz-option nzLabel="충전" nzValue="CHARGE_ALL"></nz-option>
          <nz-option nzLabel="포인트" nzValue="TRANS_ALL"></nz-option>
          <nz-option nzLabel="충전 보너스" nzValue="BONUS"></nz-option>
        </nz-select>
      </div>
      <div class="filter-content">
        <nz-range-picker [(ngModel)]="dateRange">
        </nz-range-picker>
        <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()"
          [nzLoading]="searchBtnLoading">검색</button>
      </div>
    </div>
  </div>
  <div class="charge-table-content-row">
    <nz-table [nzData]="chargeLogLists" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="loading"
      [nzFrontPagination]="false" [nzPageIndex]="pageIdx" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th>회원</th>
          <th>요청분류</th>
          <th>예금주</th>
          <th>신청금액</th>
          <th>결과금액</th>
          <th>신청일시</th>
          <th>처리일시</th>
          <th>상태</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of chargeLogLists">
          <td nzBreakWord>
            <div class="flex" style="flex-direction: column; align-items: center;">
              <div>{{data.loginId}}</div>
              <div>({{data.username}})</div>
            </div>
          </td>
          <td nzBreakWord>{{userService.chargeType2Str(data.chargeType, data.memo)}}</td>
          <td nzBreakWord>{{data.owner}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.amount)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.resultMoney)}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.requestDate)}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.processDate)}}</td>
          <td nzBreakWord>{{userService.moneyState2Str(data.state)}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>