<div class="notice">
  <div class="notice-title">
    <img class="img" [src]="imageSrc" />
  </div>
  <div class="notice-body">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr class="notice" *ngFor="let notice of noticeList" (click)="goToNoticeDetailPage(notice.id)">
          <td class="title left">
            {{notice.title}}
          </td>
          <td class="right">
            <span class="date">
              {{commonService.formatDate(notice.createDate)}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>