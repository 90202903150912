export interface MQMessage {
  targetId: number;
  type?: string;
  message?: string;
}

export enum MQType {
  RACE = 'RACE',
  NOTICE = 'NOTICE',
  NOTE = 'NOTE',
  INQUIRY = 'INQUIRY',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
  POINT = 'POINT',
  USER = 'USER',
  ACCESS = 'ACCESS',
}
