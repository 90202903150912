<gz-layout [maxWidth]="1200">
  <div class="note-frame">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <!-- <span class="header-txt">제목</span> -->
          <nz-input-group class="flex" style="align-items: center;">
            <input type="text" nz-input formControlName="title" class="input note-title" readonly />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <!-- <span class="header-txt">내용</span> -->
          <nz-input-group class="flex" style="align-items: center;">
            <textarea nz-input formControlName="body" class="input note-content" style="height: 150px;" readonly></textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div class="submit-btn-wrapper">
        <button class="submit-btn" nz-button [nzType]="'primary'" nzDanger (click)="deleteNote($event)">삭제</button>
        <button class="submit-btn" nz-button [nzType]="'primary'">닫기</button>
      </div>
    </form>
  </div>
</gz-layout>