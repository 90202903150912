import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameGroup } from '@models';
import { GameService } from '@services';

@Component({
  selector: 'gz-game-group-tab',
  templateUrl: './game-group-tab.component.html',
  styleUrls: ['./game-group-tab.component.sass'],
})
export class GameGroupTabComponent implements OnInit {
  gameGroupList: GameGroup[] = [];

  @Input() gameGroupId: number;
  @Output() gameGroupIdChange = new EventEmitter<number>();

  @Input() showCasino = false;
  @Input() showChargeAndRefund = false;

  constructor(private gameService: GameService) {}

  async ngOnInit(): Promise<void> {
    this.gameGroupList = await this.gameService.getGameGroupList();

    if (this.showCasino) {
      this.gameGroupIdChange.emit(-1);
    } else if (this.gameGroupList.length > 0) {
      this.gameGroupId = this.gameGroupList[0].id;
      this.gameGroupIdChange.emit(this.gameGroupId);
    }
  }

  onClickBtn = (gameGroupId: number) => {
    this.gameGroupId = gameGroupId;
    this.gameGroupIdChange.emit(this.gameGroupId);
  };

  tabBtnClasses = (gameGroupId: number): string[] => {
    return this.gameGroupId === gameGroupId ? ['tab-btn'] : ['tab-btn', 'un-selected'];
  };
}
