<div class="log-wrapper">
  <div *ngIf="dataLoaded" nz-row style="margin-top: 10px">
    <button *ngIf="casinoList.length > 0" nz-button nzType="primary" [ngClass]="tabBtnClasses('CASINO')"
      (click)="onChangeValue('CASINO')">
      카지노
    </button>
    <button *ngIf="gameGroupList.length > 0" nz-button nzType="primary" [ngClass]="tabBtnClasses('POWERBALL')"
      (click)="onChangeValue('POWERBALL')">
      파워볼
    </button>
  </div>
  <div *ngIf="dataLoaded" style="margin-top: 10px">
    <div *ngIf="tabType === 'CASINO' then casino else powerball"></div>
    <ng-template #casino>
      <gz-casino-betting-log [casinoList]="casinoList" [user]="user" [isBrief]="true"></gz-casino-betting-log>
    </ng-template>
    <ng-template #powerball>
      <gz-betting-log [user]="user" [isBrief]="true"></gz-betting-log>
    </ng-template>
  </div>
</div>