<div id="appBody">
  <div id="container" [ngStyle]="containerStyle">
    <gz-popup-notice-list [noticeList]="noticeList" (close)="closeNotice($event)"></gz-popup-notice-list>
    <nz-modal [(nzVisible)]="isVisible" [nzTitle]="title" [nzFooter]="modalFooter" [nzWrapClassName]="modalClassName"
      [nzAutofocus]="null" (nzOnCancel)="isVisible = false">
      {{content}}
    </nz-modal>
    <ng-template #modalFooter>
      <button #confirmBtn class="modal-btn" nzSize="large" nz-button nzType="primary"
        (click)="isVisible = false">확인</button>
    </ng-template>
    <router-outlet></router-outlet>
  </div>
</div>