<gz-layout [maxWidth]="1200">
  <div class="casino-wrapper">
    <div *ngIf="!dataLoaded || !casinoLoaded" class="spinner">
      <nz-spin nzSimple></nz-spin>
    </div>
    <div *ngIf="dataLoaded" class="casino-background">
      <div *ngIf="casinoLoaded">
        <div class="casino-frame casino-header-txt">
          보유 금액
          <button nz-button class="casino-btn" nzType="primary" (click)="onRefresh()"
            [nzLoading]="refreshBtnLoading">새로고침</button>
        </div>
        <div class="casino-notice-wrapper casino-frame">
          <div class="info-line">
            <i class="casino-icon" nz-icon nzType="dollar" nzTheme="outline"></i>
            <span class="title-txt">보유 머니 : {{this.commonService.formatNumber(casinoUser.userMoney)}}</span>
          </div>
          <div class="info-line">
            <i class="casino-icon" nz-icon nzType="dollar" nzTheme="outline"></i>
            <span class="title-txt">보유 수수료 : {{this.commonService.formatNumber(casinoUser.userPoint)}}</span>
          </div>
          <div class="info-line">
            <i class="casino-icon" nz-icon nzType="dollar" nzTheme="outline"></i>
            <span class="title-txt">카지노 머니 : {{this.commonService.formatNumber(casinoUser.casinoMoney)}} </span>
          </div>
        </div>
        <div class="casino-frame casino-header-txt">
          <i nz-icon nzType="sync" nzTheme="outline"></i>
          머니 이동 ( {{user.solutionName}} 머니 -> 카지노 머니 )
        </div>
        <div class="casino-notice-wrapper casino-frame"
          style="display: flex; flex-direction: column; align-items: center;">
          <div class="button-wrapper">
            <nz-input-number [(ngModel)]="refundMoney" [nzMin]="0" [nzStep]="10000" class="casino-input"
              [nzPlaceHolder]="'전환 금액'" [nzFormatter]="formatComma" [nzParser]="parseComma">
            </nz-input-number>
            <button nz-button nzType="danger" [disabled]="refundDisabled" [nzLoading]="refundBtnLoading"
              (click)="onRefundBtnClick()">전환</button>
          </div>
          <div style="margin-top: 10px;" class="button-wrapper">
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(10000)">1만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(50000)">5만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(100000)">10만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(500000)">50만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(1000000)">1백만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(5000000)">5백만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addRefundMoney(10000000)">1천만</button>
            <button nz-button class="casino-btn" nzType="danger" (click)="refundMoney = 0">리셋</button>
          </div>
        </div>
        <div class="casino-frame casino-header-txt">
          <i nz-icon nzType="sync" nzTheme="outline"></i>
          머니 이동 ( 카지노 머니 -> {{user.solutionName}} 머니 )
        </div>
        <div class="casino-notice-wrapper casino-frame"
          style="display: flex; flex-direction: column; align-items: center;">
          <div class="button-wrapper">
            <nz-input-number [(ngModel)]="chargeMoney" [nzMin]="0" [nzStep]="10000" class="casino-input"
              [nzPlaceHolder]="'전환 금액'" [nzFormatter]="formatComma" [nzParser]="parseComma">
            </nz-input-number>
            <button nz-button nzType="primary" [disabled]="chargeDisabled" [nzLoading]="chargeBtnLoading"
              (click)="onChargeBtnClick()">전환</button>
          </div>
          <div style="margin-top: 10px;" class="button-wrapper">
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(10000)">1만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(50000)">5만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(100000)">10만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(500000)">50만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(1000000)">1백만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(5000000)">5백만</button>
            <button nz-button class="casino-btn" nzType="default" (click)="addChargeMoney(10000000)">1천만</button>
            <button nz-button class="casino-btn" nzType="primary" (click)="chargeMoney = 0">리셋</button>
          </div>
        </div>
        <div class="casino-frame casino-header-txt" style="display: flex; flex-direction: row">
          <div style="flex: 1; text-align: left;">
            머니이동 이력
          </div>
          <div style="flex: 1; text-align: right;">
            <button nz-button nzType="primary" [nzLoading]="chargeBtnLoading" nzDanger
              (click)="deleteWholeMoneyLog()">전체
              삭제</button>
          </div>
        </div>
        <div class="casino-frame">
          <nz-table nzShowSizeChanger [nzData]="moneyLogList" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
            [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount"
            (nzQueryParams)="onQueryParamsChange($event)" nzBordered nzOutBordered>
            <thead>
              <tr>
                <th>금액</th>
                <th>타입</th>
                <th>시간</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of moneyLogList">
                <td nzBreakWord>{{ formatComma(getAbsAmount(data.amount)) }}원</td>
                <td nzBreakWord>
                  <div [ngClass]="casinoService.moneyTypeToClass(data.amount)">
                    {{ casinoService.moneyTypeToStr(data.amount) }}
                  </div>
                </td>
                <td nzBreakWord>{{ commonService.formatDateTime(data.createDate) }}</td>
                <td nzBreakWord>
                  <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="삭제하시겠습니까?"
                    (nzOnConfirm)="deleteRow(data.id)" nzDanger>삭제</a>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</gz-layout>