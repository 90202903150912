import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BalanceType, User } from '@models';
import { UserService } from '@services';

@Component({
  selector: 'gz-balance-select',
  templateUrl: './balance-select.component.html',
  styleUrls: ['./balance-select.component.sass'],
})
export class BalanceSelectComponent implements OnInit {
  @Input() user = new User();
  @Input() balanceType: BalanceType | 'ALL' = 'ALL';
  @Output() balanceTypeChange = new EventEmitter<BalanceType | 'ALL'>();

  balanceTypeList: ('ALL' | BalanceType)[] = [
    'ALL',
    'BET',
    'WIN',
    'CHARGE',
    'REFUND',
    'TRANS',
    'TRANSFER',
    'PAYBACK',
    'CANCEL',
    'EXCEED_WIN_LIMIT',
  ];

  constructor(public userService: UserService) {}

  ngOnInit(): void {
    if (this.user.userDepositEnabled) {
      this.balanceTypeList.push('PARTNER_CHARGE');
    }
    if (this.user.userWithdrawEnabled) {
      this.balanceTypeList.push('PARTNER_REFUND');
    }
  }

  onChangeValue = () => {
    this.balanceTypeChange.emit(this.balanceType);
  };
}
