import { Injectable } from '@angular/core';
import { User } from '@models';
import { Subject } from 'rxjs';

@Injectable()
export class ContentService {
  private signInFailSrc = new Subject<null>();
  signInFail$ = this.signInFailSrc.asObservable();

  private recommendFailSrc = new Subject<null>();
  recommendFail$ = this.recommendFailSrc.asObservable();

  private signInSrc = new Subject();
  signIn$ = this.signInSrc.asObservable();

  private signOutSrc = new Subject();
  signOut$ = this.signOutSrc.asObservable();

  private raceChangeSrc = new Subject();
  raceChange$ = this.raceChangeSrc.asObservable();

  private noticeChangeSrc = new Subject();
  noticeChange$ = this.noticeChangeSrc.asObservable();

  private noteChangeSrc = new Subject();
  noteChange$ = this.noteChangeSrc.asObservable();

  private inquiryChangeSrc = new Subject();
  inquiryChange$ = this.inquiryChangeSrc.asObservable();

  private chargeChangeSrc = new Subject();
  chargeChange$ = this.chargeChangeSrc.asObservable();

  private refundChangeSrc = new Subject();
  refundChange$ = this.refundChangeSrc.asObservable();

  private pointChangeSrc = new Subject();
  pointChange$ = this.pointChangeSrc.asObservable();

  private userChangeSrc = new Subject<User>();
  userChange$ = this.userChangeSrc.asObservable();

  private messageChangeSrc = new Subject();
  messageChange$ = this.messageChangeSrc.asObservable();

  private reloadTableSrc = new Subject<null>();
  reloadTable$ = this.reloadTableSrc.asObservable();

  private clearUserInfoSrc = new Subject<null>();
  clearUserInfo$ = this.clearUserInfoSrc.asObservable();

  private fiveSecondIntervalSrc = new Subject<null>();
  fiveSecondInterval$ = this.fiveSecondIntervalSrc.asObservable();

  public signInFail = () => {
    this.signInFailSrc.next();
  };

  public recommendFail = () => {
    this.recommendFailSrc.next();
  };

  public signIn = () => {
    this.signInSrc.next();
  };

  public signOut = () => {
    this.signOutSrc.next();
  };

  public onRaceChange = () => {
    this.raceChangeSrc.next();
  };

  public onNoticeChange = () => {
    this.noticeChangeSrc.next();
  };

  public onNoteChange = () => {
    this.noteChangeSrc.next();
  };

  public onInquiryChange = () => {
    this.inquiryChangeSrc.next();
  };

  public onChargeChange = () => {
    this.chargeChangeSrc.next();
  };

  public onRefundChange = () => {
    this.refundChangeSrc.next();
  };

  public onPointChange = () => {
    this.pointChangeSrc.next();
  };

  public onUserChange = (user: User) => {
    this.userChangeSrc.next(user);
  };

  public onMessageChange = (
    content: string,
    messageType: 'success' | 'info' | 'warning' | 'error',
  ) => {
    this.messageChangeSrc.next({ content, messageType });
  };

  public reloadTable = () => {
    this.reloadTableSrc.next();
  };

  public clearUserInfo = () => {
    this.clearUserInfoSrc.next();
  };

  public fiveSecondNext = () => {
    this.fiveSecondIntervalSrc.next();
  };
}
