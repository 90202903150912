<gz-layout [maxWidth]="1200">
  <div class="notice-frame">
    <div style="font-size: 16px; color: rgba(0,0,0,.85);">보유 수수료 금액을 배팅머니로 전환합니다.</div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">1회 요청가능 최소금액: {{pointMinMoney}}</div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">재 신청 가능 시간: {{pointLimitTime}}분</div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">현재 수수료 총액: {{formatComma(userPoint)}}원</div>
  </div>
  <div class="point-frame input-frame">
    <nz-input-number [(ngModel)]="pointMoney" [nzMin]="0" [nzStep]="10000" class="point-input"
      [nzPlaceHolder]="'전환 요청 금액'" [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
    <button nz-button nzType="primary" [disabled]="disabled" [nzLoading]="pointBtnLoading"
      (click)="onPointBtnClick()">전환신청</button>
  </div>
  <div *ngIf="pointTransferLogEnabled" class="point-frame">
    <nz-table nzShowSizeChanger [nzData]="pointLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>신청일</th>
          <th>금액</th>
          <th>결과</th>
          <th>상태</th>
          <th>처리일자</th>
          <th>기능</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of pointLists">
          <td nzBreakWord>{{ commonService.formatDateTime(data.requestDate) }}</td>
          <td nzBreakWord>{{ formatComma(data.amount) }}원</td>
          <td nzBreakWord>{{ formatComma(data.resultMoney) }}원</td>
          <td nzBreakWord>
            <div [ngClass]="moneyService.moneyStateToClass(data.state)">
              {{ moneyService.moneyStateToStr(data.state) }}
            </div>
          </td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.processDate) }}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="삭제하시겠습니까?" (nzOnConfirm)="deleteRow(data.id)"
              nzDanger>삭제</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>