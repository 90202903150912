import { Injectable } from '@angular/core';
import { ContentService } from '../content';

@Injectable()
export class MessageService {
  constructor(private contentService: ContentService) {}

  error = (content: string) => {
    this.contentService.onMessageChange(content, 'error');
  };

  success = (content: string) => {
    this.contentService.onMessageChange(content, 'success');
  };

  warning = (content: string) => {
    this.contentService.onMessageChange(content, 'warning');
  };

  info = (content: string) => {
    this.contentService.onMessageChange(content, 'info');
  };
}
