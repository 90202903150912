import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  AuthService,
  ContentService,
  LocalStorageService,
  ModalService,
  NavigationService,
  UserService,
} from '@services';

import { Notice, Solution } from '@models';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('confirmBtn') btnElement;
  subs: Subscription[] = [];

  solution: Solution;

  isVisible = false;
  title = '';
  content = '';

  modalClassName;

  noticeList: Notice[] = [];

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private titleService: Title,
    private contentService: ContentService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.receiveMessage();
    this.loadSolutionSetting();
    this.subs.push(
      this.modalService.error$.subscribe(message => {
        const { title, content, className } = message;
        this.title = title;
        this.content = content;
        this.isVisible = true;
        this.modalClassName = className;
        setTimeout(() => {
          this.btnElement.elementRef.nativeElement.focus();
        }, 0);
      }),
      this.modalService.popup$.subscribe(notice => {
        const found = this.noticeList.find(_notice => _notice.id === notice.id);
        if (found) {
          return;
        }
        this.noticeList.push(notice);
      }),
      this.contentService.signOut$.subscribe(() => {
        this.localStorageService.deleteTokens();
        this.userService.clearCacheUser();
        this.navigationService.goToSignInPage();
      }),
    );
  }

  receiveMessage = () => {
    window.addEventListener('message', e => {
      try {
        const data = JSON.parse(e['data']);
        if (data && data.token) {
          this.localStorageService.saveAccessToken(data.token);
          this.localStorageService.saveRefreshToken(data.token);
          this.navigationService.goToHomePage();
        }
      } catch (ex) {}
    });
  };

  loadSolutionSetting = async () => {
    this.solution = await this.authService.getCachedSolutionInfo();
    this.setFavicon();
    this.setUserLogoUrl();
    this.setTitle();
  };

  setFavicon = async () => {
    if (!this.solution || !this.solution.userFaviconId) {
      return;
    }
    const icon = document.querySelector('#appIcon');
    const url = await this.authService.getFileUrl(this.solution && this.solution.userFaviconId);
    icon['href'] = `${url}`;
  };

  setUserLogoUrl = async () => {
    if (!this.solution || !this.solution.userLogoId) {
      return;
    }
    const url = await this.authService.getFileUrl(this.solution && this.solution.userLogoId);
    this.localStorageService.saveLogoUrl(url);
  };

  setTitle = async () => {
    this.titleService.setTitle(this.solution && this.solution.solutionName);
  };

  closeNotice = (notice: Notice) => {
    this.noticeList = this.noticeList.filter(elem => elem.id !== notice.id);
  };

  get containerStyle() {
    if (this.authService.isAuthenticated()) {
      return {};
    }
    return {
      height: '100%',
    };
  }

  ngOnDestroy(): void {
    this.subs.map(sub => sub && sub.unsubscribe());
  }
}
