import { Component, OnInit } from '@angular/core';
import { Notice } from '@models';
import { AuthService, CommonService, NavigationService, NoticeService } from '@services';

@Component({
  selector: 'gz-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.sass'],
})
export class NoticeListComponent implements OnInit {
  noticeList: Notice[] = [];

  imageSrc = '';

  constructor(
    private authService: AuthService,
    public commonService: CommonService,
    private noticeService: NoticeService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.noticeService.getNoticeList(0, 5).then(noticeList => (this.noticeList = noticeList));
    this.imageSrc = this.authService.cachedSolution?.userTheme.notice;
  }

  goToNoticeDetailPage = (noticeId: number) => {
    this.navigationService.goToDetailNoticePage(noticeId);
  };
}
