import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InquiryService, NavigationService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';
import { Subject } from 'rxjs';

@Component({
  selector: 'gz-create-inquiry',
  templateUrl: './create-inquiry.component.html',
  styleUrls: ['./create-inquiry.component.sass'],
})
export class CreateInquiryComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();
  validateForm!: FormGroup;
  loading = false;

  inquiryTitleList = [
    '계좌문의',
    '유저승인',
    '충전지연',
    '환전지연',
    '버그신고',
    '1원인증',
    '기타',
  ];

  inquiryLists = [];

  constructor(
    private fb: FormBuilder,
    private inquiryService: InquiryService,
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      body: [null, [Validators.required]],
    });
  }

  submitForm = async (): Promise<void> => {
    if (this.loading) {
      return;
    }
    this.loading = true;
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      try {
        await this.inquiryService.createInquiry(this.validateForm.value);
        this.modal.destroy();
      } catch (ex) {}
    }
    this.loading = false;
  };

  get buttonDisabled() {
    return this.loading || !this.validateForm.valid;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
