<gz-layout [maxWidth]="1200">
  <div class="refund-wrapper">
    <div class="refund-background">
      <div class="refund-frame refund-header-txt">
        출금안내
      </div>
      <div class="refund-notice-wrapper refund-frame">
        <pre class="description" style="margin-bottom: 0px;">{{notice}}</pre>
      </div>
      <div class="refund-frame refund-header-txt">
        출금 신청
      </div>
      <div class="refund-notice-wrapper refund-frame"
        style="display: flex; flex-direction: column; align-items: center;">
        <div class="button-wrapper">
          <nz-input-number [(ngModel)]="refundMoney" [nzMin]="0" [nzStep]="10000" class="refund-input"
            [nzPlaceHolder]="'출금 요청 금액'" [nzFormatter]="formatComma" [nzParser]="parseComma">
          </nz-input-number>
          <button nz-button nzType="primary" nzDanger [disabled]="disabled" [nzLoading]="refundBtnLoading"
            (click)="onRefundBtnClick()">출금신청</button>
        </div>
        <div style="margin-top: 10px;">
          출금계좌 : {{account}}
        </div>
        <div class="button-wrapper" style="margin-top: 10px;">
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(10000)">1만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(50000)">5만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(100000)">10만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(500000)">50만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(1000000)">1백만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(5000000)">5백만</button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(10000000)">1천만</button>
          <button nz-button class="refund-btn" nzType="primary" (click)="refundMoney = 0">리셋</button>
        </div>
      </div>
      <div class="refund-frame refund-header-txt" style="display: flex;">
        <div style="flex: 1; text-align: left;">
          출금 이력
        </div>
        <div style="flex: 1; text-align: right;">
          <button nz-button nzType="primary" [nzLoading]="refundBtnLoading" nzDanger (click)="deleteWholeRefund()">전체
            삭제</button>
        </div>
      </div>
      <div class="refund-frame">
        <nz-table nzShowSizeChanger [nzData]="refundLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
          [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount"
          (nzQueryParams)="onQueryParamsChange($event)" nzBordered nzOutBordered>
          <thead>
            <tr>
              <th>금액</th>
              <th>상태</th>
              <th>요청일시</th>
              <th>처리일시</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of refundLists">
              <td nzBreakWord>{{ formatComma(data.amount) }}원</td>
              <td nzBreakWord>
                <div [ngClass]="moneyService.moneyStateToClass(data.state)">
                  {{ moneyService.moneyStateToStr(data.state) }}
                </div>
              </td>
              <td nzBreakWord>{{ commonService.formatDateTime(data.requestDate) }}</td>
              <td nzBreakWord>{{ commonService.formatDateTime(data.processDate) }}</td>
              <td nzBreakWord>
                <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="삭제하시겠습니까?"
                  (nzOnConfirm)="deleteRow(data.id)" nzDanger>삭제</a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</gz-layout>
<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()">

  <ng-template #modalTitle>
    출금 비밀번호 입력
  </ng-template>

  <ng-template #modalContent>
    <input type="password" nz-input placeholder="출금 비밀번호" [(ngModel)]="accountPasswd" />
  </ng-template>
</nz-modal>