import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Game } from '@models';
import { GameService } from '@services';

@Component({
  selector: 'gz-game-tab',
  templateUrl: './game-tab.component.html',
  styleUrls: ['./game-tab.component.sass'],
})
export class GameTabComponent implements OnInit {
  gameList: Game[] = [];

  @Input() gameId: number;
  @Input() gameGroupId: number;

  @Output() gameIdChange = new EventEmitter<number>();
  @Output() gameGroupIdChange = new EventEmitter<number>();

  constructor(private gameService: GameService) {}

  async ngOnInit(): Promise<void> {
    const gameGroupList = await this.gameService.getGameGroupList();
    this.gameList = gameGroupList.reduce(
      (prevGameGroup, nextGameGroup) => [...prevGameGroup, ...nextGameGroup.gameList],
      [],
    );
    if (this.gameList.length > 0) {
      const game = this.gameList[0];
      this.onChangeValue(game.groupId, game.gameId);
    }
  }

  onChangeValue = (gameGroupId: number, gameId: number) => {
    this.gameGroupId = gameGroupId;
    this.gameId = gameId;
    this.gameGroupIdChange.emit(this.gameGroupId);
    this.gameIdChange.emit(this.gameId);
  };

  tabBtnClasses = (gameGroupId: number, gameId: number): string[] => {
    return this.gameGroupId === gameGroupId && this.gameId === gameId
      ? ['tab-btn', 'flex1']
      : ['tab-btn', 'un-selected', 'flex1'];
  };
}
