<gz-layout [maxWidth]="1200">
  <div class="log-wrapper">
    <div class="filter-row">
      <div class="filter-col" style="display: flex;">
        <gz-game-select [(gameId)]="gameId" [(gameGroupId)]="gameGroupId"></gz-game-select>
        <nz-date-picker style="margin-left: 10px;" [(ngModel)]="date"></nz-date-picker>
        <button nz-button nzType="primary" (click)="onSearchBettingLog()">검색</button>
      </div>
      <div class="align-right">
        <button nz-button nzType="primary" (click)="onHideAllBettingLogBtnClick()" nzDanger
          style="border-radius: 5px">전체삭제</button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <nz-table nzShowSizeChanger [nzFrontPagination]="false" [nzData]="bettingLogList" [nzPageIndex]="pageIdx"
        [nzFrontPagination]="false" [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount"
        (nzQueryParams)="onQueryParamsChange($event)" nzBordered nzOutBordered>
        <thead>
          <tr>
            <th>게임</th>
            <th>회차</th>
            <th>상태</th>
            <th>배팅</th>
            <th>배팅금액</th>
            <th>당첨금액</th>
            <th>일시</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of bettingLogList">
            <td nzBreakWord>{{data.gameName}}</td>
            <td nzBreakWord>{{data.raceTitle}}</td>
            <td nzBreakWord>
              <div [ngClass]="gameService.gameResultTypeToClass(data.state)">
                {{gameService.gameResultTypeToStr(data.state)}}
              </div>
            </td>
            <td nzBreakWord>{{data.pickName}}/{{data.rate}}x</td>
            <td nzBreakWord>{{commonService.formatNumber(data.betMoney)}}</td>
            <td nzBreakWord>{{commonService.formatNumber(data.winMoney)}}</td>
            <td nzBreakWord>{{commonService.formatDateTime(data.createDate)}}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</gz-layout>