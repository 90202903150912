import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContentService } from '../content';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private contentService: ContentService) {}

  async canActivate(): Promise<boolean> {
    if (!this.authService.isAuthenticated()) {
      this.contentService.signOut();
      return false;
    }
    return true;
  }
}
