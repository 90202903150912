import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CasinoService } from '../casino';

import { ContentService } from '../content';

@Injectable()
export class CasinoGuardService implements CanActivate {
  constructor(private casinoService: CasinoService, private contentService: ContentService) {}

  async canActivate(): Promise<boolean> {
    const casinoList = await this.casinoService.getCasinoList();
    if (casinoList.length < 1) {
      this.contentService.signOut();
      return false;
    }
  }
}
