import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CommonService,
  ModalService,
  SolutionService,
  MoneyService,
  ContentService,
  UserService,
  InquiryService,
} from '@services';
import { BonusCharge, ChargeData, User } from '@models';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gz-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.sass'],
})
export class ChargeComponent implements OnInit, OnDestroy {
  notice = '';

  chargeMoney: number;

  bonusChargeId = 0;

  accountBtnLoading = false;
  chargeBtnLoading = false;

  pageIdx = 1;
  pageSize = 20;
  totalCount = 0;
  loading = true;

  user: User;

  chargeLists: ChargeData[] = [];
  bonusChargeList: BonusCharge[] = [];

  subs: Subscription[] = [];
  destroyed = new Subject<any>();

  constructor(
    private contentService: ContentService,
    private commonService: CommonService,
    private modalService: ModalService,
    private solutionService: SolutionService,
    private moneyService: MoneyService,
    private userService: UserService,
    private inquiryService: InquiryService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.notice = await this.solutionService.getSolutionChargeNotice();
    await this.loadUserData();
    await this.loadBonusChargeList();
    this.subs.push(
      this.contentService.chargeChange$.subscribe(async () => {
        this.loadData();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        this.pageIdx = 1;
        this.pageSize = 20;
        this.totalCount = 0;
        this.loadData();
      });
  }

  loadData = async () => {
    const promises = [this.loadUserData(), this.loadChargeList()];
    await Promise.all(promises);
  };

  loadUserData = async () => {
    this.user = await this.userService.getUserInfo();
    // this.owner = user.owner;
    setTimeout(() => {
      this.contentService.onUserChange(this.user);
    }, 0);
  };

  onAccountNumberConfirm = async () => {
    this.accountBtnLoading = true;
    try {
      await this.modalService.confirm('입금 계좌', '입금계좌 안내를 받으시겠습니까?');
      if (this.switeCoinChargeEnabled) {
        window.open(this.user.switeCoinChargeUrl, '_blank');
      } else {
        const accountNumber = await this.solutionService.getSolutionAccountNumber();
        await this.modalService.info('입금 계좌', `${accountNumber}`);
      }
      this.accountBtnLoading = false;
    } catch (ex) {
      this.accountBtnLoading = false;
    }
  };

  onClickCreateInquiry = async () => {
    this.accountBtnLoading = true;
    try {
      await this.modalService.confirm('1:1 계좌문의', '계좌문의 문의를 하시겠습니까?');
      const inquiry: any = {
        title: '계좌문의',
        body: '계좌문의 문의 드립니다.',
      };
      await this.inquiryService.createInquiry(inquiry);
      await this.modalService.success(
        '1:1 계좌문의',
        '계좌문의 문의가 완료되었습니다. 1:1 문의에서 확인 부탁드립니다.',
      );
      this.accountBtnLoading = false;
    } catch (ex) {
      this.accountBtnLoading = false;
    }
  };

  onChargeBtnClick = async () => {
    if (!this.chargeMoney || this.chargeMoney <= 0) {
      this.modalService.warning('충전', '충전금액을 확인해주세요');
      return;
    }
    if (this.bonusChargeList.length > 0 && this.bonusChargeId === 0) {
      this.modalService.warning('충전', '보너스 충전을 선택해주세요');
      return;
    }
    this.chargeBtnLoading = true;
    try {
      await this.modalService.confirm(
        '입금 신청',
        `${this.formatComma(this.chargeMoney)}원을 입금신청 하시겠습니까?`,
      );

      const chargeMoney = +this.chargeMoney;
      const found = this.bonusChargeList.find(bonusCharge => bonusCharge.id === this.bonusChargeId);
      await this.moneyService.addCharge(
        chargeMoney,
        found ? (found.rate / 100) * chargeMoney : 0,
        found?.title,
      );
      this.chargeMoney = null;
      this.loadData();
    } catch (ex) {}
    this.chargeBtnLoading = false;
  };

  loadChargeList = async () => {
    this.loading = true;
    try {
      this.totalCount = await this.moneyService.getChargeTotalCount();
      this.chargeLists = await this.moneyService.getChargeList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  loadBonusChargeList = async () => {
    this.bonusChargeList = await this.moneyService.getBonusChargeList();
  };

  deleteWholeCharge = async () => {
    try {
      await this.modalService.deleteConfirm('전체삭제', '충전이력을 전체 삭제하시겠습니까?');
      await this.moneyService.deleteWholeCharge();
      await this.loadChargeList();
    } catch (ex) {}
  };

  get disabled(): boolean {
    return !this.chargeMoney || this.chargeMoney <= 0 || !this.isBonusChargeSelected;
  }

  get isBonusChargeSelected() {
    if (this.bonusChargeList.length == 0) {
      return true;
    }
    return this.bonusChargeId !== 0;
  }

  get switeCoinChargeEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.switeCoinChargeEnabled;
  }

  get switeCoinChargeContent() {
    if (!this.user) {
      return '';
    }
    return this.user.switeCoinChargeContent;
  }

  get virtualChargeEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.virtualChargeEnabled;
  }

  get virtualChargeContent() {
    if (!this.user) {
      return '';
    }
    return this.user.virtualChargeContent;
  }

  get noticeContent() {
    if (!this.user) {
      return '';
    }
    if (this.virtualChargeEnabled) {
      return this.virtualChargeContent;
    }
    return this.notice;
  }

  deleteRow = async (id: number): Promise<void> => {
    this.loading = true;
    try {
      await this.moneyService.updateCharge(id, false);
      this.totalCount -= 1;
      this.chargeLists = this.chargeLists.filter(d => d.id !== +id);
    } catch (ex) {}
    this.loading = false;
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    await this.loadChargeList();
  };

  addChargeMoney = (money: number) => {
    if (!this.chargeMoney) {
      this.chargeMoney = 0;
    }
    this.chargeMoney += money;
  };

  get owner() {
    if (!this.user) {
      return '';
    }
    return this.user.owner;
  }

  formatComma = (num: number) => this.commonService.formatNumber(`${num}`);
  parseComma = (value: string) => this.commonService.formatToNumber(value);

  getLabelValue = (bonusCharge: BonusCharge) => {
    return bonusCharge.id;
  };

  ngOnDestroy(): void {
    this.subs.map(sub => sub.unsubscribe());
    this.destroyed.next();
    this.destroyed.complete();
  }
}
