import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GameGroup, Game, Pick, BettingLog, GameResultType, PickType } from '@models';
import { CommonService } from '../common';

@Injectable()
export class GameService {
  url = '/api/gameGroup';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getGameGroupList = (): Promise<GameGroup[]> => {
    return this.http.get<GameGroup[]>(`${this.url}`).toPromise();
  };

  getGameGroup = (gameGroupId: number): Promise<GameGroup> => {
    return this.http.get<GameGroup>(`${this.url}/${gameGroupId}`).toPromise();
  };

  getGameLists = (gameGroupId: number): Promise<Game[]> => {
    return this.http.get<Game[]>(`${this.url}/${gameGroupId}/game`).toPromise();
  };

  bet = (
    betMoney: number,
    pickId: number,
    gameGroupId: number,
    gameId: number,
    raceId: number,
  ): Promise<Pick> => {
    return this.http
      .post<Pick>(`${this.url}/${gameGroupId}/race/${raceId}/game/${gameId}/bet`, {
        betMoney,
        pickId,
      })
      .toPromise();
  };

  getTotalCount = (gameGroupId: number, gameId: number, date?: Date): Promise<number> => {
    let params = new HttpParams();
    if (date) {
      params = params.append('startDate', this.commonService.formatDateStr(date));
    }
    return this.http
      .get<number>(`${this.url}/${gameGroupId}/game/${gameId}/bet/count`, { params })
      .toPromise();
  };

  getBettingLogListByRaceId = (
    gameGroupId: number,
    gameId: number,
    pageIdx: number,
    pageSize: number,
    raceId: number,
  ): Promise<BettingLog[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIdx.toString())
      .append('pageSize', pageSize.toString())
      .append('raceId', raceId.toString());
    return this.http
      .get<BettingLog[]>(`${this.url}/${gameGroupId}/game/${gameId}/bet`, { params })
      .toPromise();
  };

  getBettingLogListByRaceIdCount = (
    gameGroupId: number,
    gameId: number,
    raceId: number,
  ): Promise<number> => {
    let params = new HttpParams();
    params = params.append('raceId', raceId.toString());
    return this.http
      .get<number>(`${this.url}/${gameGroupId}/game/${gameId}/bet/count`, { params })
      .toPromise();
  };

  getBettingLogList = (
    gameGroupId: number,
    gameId: number,
    pageIdx: number,
    pageSize: number,
    date?: Date,
  ): Promise<BettingLog[]> => {
    let params = new HttpParams();
    params = params.append('pageIndex', pageIdx.toString());
    params = params.append('pageSize', pageSize.toString());
    if (date) {
      params = params.append('startDate', this.commonService.formatDateStr(date));
    }
    return this.http
      .get<BettingLog[]>(`${this.url}/${gameGroupId}/game/${gameId}/bet`, { params })
      .toPromise();
  };

  updateBetLogVisible = (visible: boolean, gameGroupId: number, gameId: number): Promise<void> => {
    return this.http
      .patch<void>(`${this.url}/${gameGroupId}/game/${gameId}/bet`, { visible })
      .toPromise();
  };

  gameResultTypeToClass = (resultType: GameResultType) => {
    switch (resultType) {
      case GameResultType.BET:
        return 'game-result-bet';
      case GameResultType.WIN:
        return 'game-result-win';
      case GameResultType.LOSE:
        return 'game-result-lose';
      case GameResultType.REFUND:
        return 'game-result-refund';
      default:
        return '';
    }
  };

  gameResultTypeToStr = (resultType: GameResultType) => {
    switch (resultType) {
      case GameResultType.BET:
        return '배팅';
      case GameResultType.WIN:
        return '당첨';
      case GameResultType.LOSE:
        return '낙첨';
      case GameResultType.REFUND:
        return '환불';
      default:
        return '';
    }
  };

  getRaceTitle = (gameId: number | string, raceTitle: string, dailyNo: number): string => {
    return `${dailyNo}`;
  };

  getGameIdFromGameGroupId = (gameGroupId: number) => {
    const id = Number(gameGroupId);
    // 파워볼
    if (id === 1) {
      return 1;
    } // 파워 사다라
    else if (id === 2) {
      return 6;
    } // 럭키 5
    else if (id === 3) {
      return 8;
    } // EOS 파워볼
    else if (id === 4) {
      return 10;
      // return
    } // EOS 파워볼 사다리
    else if (id === 5) {
      return 15;
      // return
    } else {
      return 99999;
    }
  };

  findPick = (gameList: Game[], pickId: number) => {
    let pick;
    gameList.forEach(game => {
      game.pickGroupList.forEach(pickGroup => {
        const find = pickGroup.pickList.filter(elem => elem.id === pickId);
        if (find.length > 0) {
          pick = find[0];
          pick.pickGroupSortOrder = pickGroup.sortOrder;
        }
      });
    });
    return pick;
  };
}
