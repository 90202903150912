import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BettingSummary,
  ChargeType,
  MoneyState,
  NormalPartner,
  PartnerPointSummary,
  BettingLog,
  StepHeader,
  User,
  UserCharge,
  UserSearchType,
  UserPoint,
  RankingType,
  UserRanking,
  UserRankingBettingTotalMoney,
  GameGroup,
  CasinoGame,
  CasinoBet,
  CasinoUserAccount,
  UserState,
  CasinoSearchType,
  PageModel,
} from '@models';

import { Observable } from 'rxjs';

import { CommonService } from '../common';

@Injectable()
export class PartnerService {
  url = '/api/partner';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getStepHeaderList = (): Promise<StepHeader[]> => {
    return this.http.get<StepHeader[]>(`${this.url}/level`).toPromise();
  };

  getPartnerList = async () => {
    const partnerList = await this.http.get<NormalPartner[]>(`${this.url}`).toPromise();
    return partnerList.map((partner, index) => {
      if (index === 0) {
        partner.expand = true;
      }
      return partner;
    });
  };

  // getPartnerList = (
  //   pageIndex: number,
  //   pageSize: number,
  //   searchType: UserSearchType,
  //   searchKeyword: string,
  // ): Promise<NormalPartner[]> => {
  //   let params = new HttpParams();
  //   params = params
  //     .append('pageIndex', pageIndex.toString())
  //     .append('pageSize', pageSize.toString())
  //     .append('searchType', searchType);
  //   if (searchKeyword) {
  //     params = params.append('searchKeyword', searchKeyword);
  //   }
  //   return this.http
  //     .get<NormalPartner[]>(`${this.url}`, { params })
  //     .toPromise();
  // };

  // getPartnerListCount = (searchType: UserSearchType, searchKeyword: string): Promise<number> => {
  //   let params = new HttpParams();
  //   params = params.append('searchType', searchType);
  //   if (searchKeyword) {
  //     params = params.append('searchKeyword', searchKeyword);
  //   }
  //   return this.http
  //     .get<number>(`${this.url}/count`, { params })
  //     .toPromise();
  // };

  getPartnerListSummary = (
    searchType: UserSearchType,
    searchKeyword: string,
  ): Promise<PartnerPointSummary> => {
    let params = new HttpParams();
    params = params.append('searchType', searchType);
    if (searchKeyword) {
      params = params.append('searchKeyword', searchKeyword);
    }
    return this.http
      .get<PartnerPointSummary>(`${this.url}/total`, { params })
      .toPromise();
  };

  getPartnerUserMoney = async (userId: number): Promise<number> => {
    return this.http.get<number>(`${this.url}/user/${userId}/userMoney`).toPromise();
  };

  createUser = (user: User): Promise<User> => {
    return this.http.post<User>(`${this.url}/user`, user).toPromise();
  };

  getPartnerPointRate = (userId: number, gameGroupId: number): Promise<GameGroup> => {
    return this.http
      .get<GameGroup>(`${this.url}/user/${userId}/gameGroup/${gameGroupId}/pointRate`)
      .toPromise();
  };

  getCasinoPartnerPointRate = (userId: number, casinoId: number): Promise<CasinoGame[]> => {
    return this.http
      .get<CasinoGame[]>(`${this.url}/user/${userId}/casino/${casinoId}/pointRate`)
      .toPromise();
  };

  updatePartnerPointRate = (
    userId: number,
    pointRate: GameGroup,
    gameGroupId: number,
  ): Promise<GameGroup> => {
    return this.http
      .patch<GameGroup>(`${this.url}/user/${userId}/gameGroup/${gameGroupId}/pointRate`, pointRate)
      .toPromise();
  };

  updateCasinoPartnerPointRate = (
    userId: number,
    casinoId: number,
    gameList: CasinoGame[],
  ): Promise<GameGroup> => {
    return this.http
      .patch<GameGroup>(`${this.url}/user/${userId}/casino/${casinoId}/pointRate`, gameList)
      .toPromise();
  };

  getBettingLogList = (
    userId: number,
    gameGroupId: number,
    gameId: number,
    pageIdx: number,
    pageSize: number,
    startDate: Date,
  ): Promise<BettingLog[]> => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIdx.toString())
      .append('pageSize', pageSize.toString())
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());

    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    return this.http
      .get<BettingLog[]>(`${this.url}/user/${userId}/bet`, { params })
      .toPromise();
  };

  getBettingLogListCount = (
    userId: number,
    gameGroupId: number,
    gameId: number,
    startDate: Date,
  ): Promise<number> => {
    let params = new HttpParams();
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    return this.http
      .get<number>(`${this.url}/user/${userId}/bet/count`, { params })
      .toPromise();
  };

  getBettingLogListTotal = (
    userId: number,
    gameGroupId: number,
    gameId: number,
    startDate: Date,
  ): Promise<BettingSummary> => {
    let params = new HttpParams();
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    return this.http
      .get<BettingSummary>(`${this.url}/user/${userId}/bet/total`, { params })
      .toPromise();
  };

  getCasinoBettingLogList = (
    userId: number,
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    startDate: Date,
    casinoSearchType: CasinoSearchType,
  ) => {
    let params = new HttpParams();
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (casinoSearchType && casinoSearchType !== CasinoSearchType.ALL) {
      params = params.append('providerType', casinoSearchType);
    }
    return this.http
      .get<CasinoBet[]>(`${this.url}/user/${userId}/casino/${casinoId}/bet`, { params })
      .toPromise();
  };

  getCasinoBettingLogCount = (
    userId: number,
    casinoId: number,
    startDate: Date,
    casinoSearchType: CasinoSearchType,
  ) => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (casinoSearchType && casinoSearchType !== CasinoSearchType.ALL) {
      params = params.append('providerType', casinoSearchType);
    }
    return this.http
      .get<CasinoBet[]>(`${this.url}/user/${userId}/casino/${casinoId}/bet/count`, {
        params,
      })
      .toPromise();
  };

  getUserMoneyList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    type: 'charge' | 'refund',
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
  ): Promise<UserCharge[]> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate, state, chargeType);
    return this.http
      .get<UserCharge[]>(`${this.url}/user/${userId}/${type}`, { params })
      .toPromise();
  };

  getUserMoneyListCount = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    type: 'charge' | 'refund',
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
  ): Promise<number> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate, state, chargeType);
    return this.http
      .get<number>(`${this.url}/user/${userId}/${type}/count`, { params })
      .toPromise();
  };

  getUserMoneyListTotal = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    type: 'charge' | 'refund',
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
  ): Promise<number> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate, state, chargeType);
    return this.http
      .get<number>(`${this.url}/user/${userId}/${type}/total`, { params })
      .toPromise();
  };

  getUserPointList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    gameGroupId: number,
    gameId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<UserPoint[]> => {
    let params = this.generateParams(pageIndex, pageSize, startDate, endDate, '0', '0');
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    return this.http
      .get<UserPoint[]>(`${this.url}/user/${userId}/point`, { params })
      .toPromise();
  };

  getUserPointListCount = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    gameGroupId: number,
    gameId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<number> => {
    let params = this.generateParams(pageIndex, pageSize, startDate, endDate, '0', '0');
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    return this.http
      .get<number>(`${this.url}/user/${userId}/point/count`, { params })
      .toPromise();
  };

  getUserPointListTotal = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    gameGroupId: number,
    gameId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<number> => {
    let params = this.generateParams(pageIndex, pageSize, startDate, endDate, '0', '0');
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    return this.http
      .get<number>(`${this.url}/user/${userId}/point/total`, { params })
      .toPromise();
  };

  getCasinoRankingList = (
    casinoId: number,
    startDate?: Date,
    endDate?: Date,
    sortOrder?: string,
    sortColumn?: string,
  ): Promise<CasinoUserAccount[]> => {
    let params = new HttpParams().append('casinoId', casinoId.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortColumn) {
      params = params.append('sortColumn', sortColumn);
    }
    return this.http
      .get<CasinoUserAccount[]>(`${this.url}/rank/casino`, { params })
      .toPromise();
  };

  getRankingList = (
    gameGroupId: number,
    gameId: number,
    rankingType: RankingType,
    startDate?: Date,
    endDate?: Date,
    sortOrder?: 'ASC' | 'DESC',
  ): Promise<UserRanking[]> => {
    const type = this.rankingType2UrlStr(rankingType);
    let params = new HttpParams();
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }
    return this.http
      .get<UserRanking[]>(`${this.url}/rank/${type}`, { params })
      .toPromise();
  };

  getChargeList = (
    startDate: Date,
    endDate: Date,
    sortOrder: 'ASC' | 'DESC',
  ): Promise<UserRanking[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('sortOrder', sortOrder);

    return this.http
      .get<UserRanking[]>(`${this.url}/rank/charge`, { params })
      .toPromise();
  };

  getRefundList = (
    startDate: Date,
    endDate: Date,
    sortOrder: 'ASC' | 'DESC',
  ): Promise<UserRanking[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('sortOrder', sortOrder);

    return this.http
      .get<UserRanking[]>(`${this.url}/rank/refund`, { params })
      .toPromise();
  };

  getPointList = (
    startDate: Date,
    endDate: Date,
    sortOrder: 'ASC' | 'DESC',
  ): Promise<UserRanking[]> => {
    const params = new HttpParams()
      .append('startDate', this.commonService.formatDateStr(startDate))
      .append('endDate', this.commonService.formatDateStr(endDate))
      .append('sortOrder', sortOrder);

    return this.http
      .get<UserRanking[]>(`${this.url}/rank/point`, { params })
      .toPromise();
  };

  getCasinoUserPointList = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    userId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<UserPoint[]> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate);
    return this.http
      .get<UserPoint[]>(`${this.url}/user/${userId}/casino/${casinoId}/point`, { params })
      .toPromise();
  };

  getCasinoUserPointListCount = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    userId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<number> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate);
    return this.http
      .get<number>(`${this.url}/user/${userId}/casino/${casinoId}/point/count`, { params })
      .toPromise();
  };

  getCasinoUserPointListTotal = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    userId: number,
    startDate?: Date,
    endDate?: Date,
  ): Promise<number> => {
    const params = this.generateParams(pageIndex, pageSize, startDate, endDate);
    return this.http
      .get<number>(`${this.url}/user/${userId}/casino/${casinoId}/point/total`, { params })
      .toPromise();
  };

  getRankingTotal = (
    gameGroupId: number,
    gameId: number,
    rankingType: RankingType,
    startDate?: Date,
    endDate?: Date,
    sortOrder?: 'ASC' | 'DESC',
  ): Promise<number | UserRankingBettingTotalMoney> => {
    const type = this.rankingType2UrlStr(rankingType);
    let params = new HttpParams();
    params = params
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString());
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }
    return this.http
      .get<number | UserRankingBettingTotalMoney>(`${this.url}/rank/${type}/total`, {
        params,
      })
      .toPromise();
  };

  getBettingList = (
    gameGroupId: number,
    gameId: number,
    pageIndex: number,
    pageSize: number,
    startDate: Date,
  ): Observable<BettingLog[]> => {
    let params = new HttpParams()
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString())
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      const startDateStr = this.commonService.formatDateStr(startDate);
      params = params.append('startDate', startDateStr).append('endDate', startDateStr);
    }
    return this.http.get<BettingLog[]>(`${this.url}/bet`, { params });
  };

  getBettingListCount = (
    gameGroupId: number,
    gameId: number,
    pageIndex: number,
    pageSize: number,
    startDate: Date,
  ): Observable<number> => {
    let params = new HttpParams()
      .append('gameGroupId', gameGroupId.toString())
      .append('gameId', gameId.toString())
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      const startDateStr = this.commonService.formatDateStr(startDate);
      params = params.append('startDate', startDateStr).append('endDate', startDateStr);
    }
    return this.http.get<number>(`${this.url}/bet/count`, { params });
  };

  getCasinoBettingList = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    startDate: Date,
    casinoSearchType: CasinoSearchType,
  ): Observable<CasinoBet[]> => {
    let params = new HttpParams()
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      const startDateStr = this.commonService.formatDateStr(startDate);
      params = params.append('startDate', startDateStr).append('endDate', startDateStr);
    }
    if (casinoSearchType && casinoSearchType !== CasinoSearchType.ALL) {
      params = params.append('providerType', casinoSearchType);
    }
    return this.http.get<CasinoBet[]>(`${this.url}/casino/${casinoId}/bet`, { params });
  };

  getCasinoBettingListCount = (
    casinoId: number,
    pageIndex: number,
    pageSize: number,
    startDate: Date,
    casinoSearchType: CasinoSearchType,
  ): Observable<number> => {
    let params = new HttpParams()
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    if (startDate) {
      const startDateStr = this.commonService.formatDateStr(startDate);
      params = params.append('startDate', startDateStr).append('endDate', startDateStr);
    }
    if (casinoSearchType && casinoSearchType !== CasinoSearchType.ALL) {
      params = params.append('providerType', casinoSearchType);
    }
    return this.http.get<number>(`${this.url}/casino/${casinoId}/bet/count`, { params });
  };

  getLoginUserList = (
    pageIndex: number,
    pageSize: number,
    searchType: UserSearchType,
    searchKeyword: string,
  ) => {
    let params = new HttpParams()
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());

    if (searchKeyword) {
      params = params
        .append('searchType', searchType.toString())
        .append('searchKeyword', searchKeyword);
    }

    return this.http.get<PageModel<any>>(`${this.url}/user/connect`, { params });
  };

  updateUserState = (userId: number, userState: UserState) => {
    return this.http
      .patch<GameGroup>(`${this.url}/user/${userId}/userState`, { userState })
      .toPromise();
  };

  private generateParams = (
    pageIndex: number,
    pageSize: number,
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
  ): HttpParams => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (state && state !== '0') {
      params = params.append('state', state);
    }
    if (chargeType && chargeType !== '0') {
      params = params.append('chargeType', chargeType);
    }
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    return params;
  };

  rankingType2UrlStr = (rankingType: RankingType): string => {
    switch (rankingType) {
      case RankingType.BETTING_PROFIT_AND_LOSS:
        return 'bet';
      case RankingType.POINT:
        return 'point';
      case RankingType.CHARGE:
        return 'charge';
      case RankingType.REFUND:
        return 'refund';
    }
  };

  gamePointTypeToStr = (pointType: string) => {
    switch (pointType) {
      case 'BET':
        return '배팅';
      case 'WIN':
        return '당첨';
      case 'CANCEL':
        return '환불';
      default:
        return '';
    }
  };
}
