import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CommonService, UserService } from '@services';
import { PageModel, UserBalanceLog, BalanceType, User } from '@models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gz-user-balance-log',
  templateUrl: './user-balance-log.component.html',
  styleUrls: ['./user-balance-log.component.sass'],
})
export class UserBalanceLogComponent implements OnInit, OnDestroy {
  @Input() user = new User();
  @Input() partner = new User();

  pageNumber = 1;
  pageSize = 40;
  totalCount = 0;

  loading = false;
  tableLoading = false;

  page: PageModel<UserBalanceLog> = {
    pageIndex: 0,
    totalPageCount: 0,
    totalDataCount: 0,
    pageSize: 0,
    data: [],
  };

  balanceType: BalanceType | 'ALL' = 'ALL';

  dataLoaded = false;

  date = new Date();
  subs: Subscription[] = [];
  sub: Subscription;

  constructor(public commonService: CommonService, public userService: UserService) {}

  ngOnInit(): void {
    this.loadPointLog();
  }

  onPageIndexChange = async (pageIdx: number) => {
    this.pageNumber = pageIdx;
    await this.loadPointLog();
  };

  onPageSizeChange = async (pageSize: number) => {
    this.pageSize = pageSize;
    await this.loadPointLog();
  };

  onSearchBtnClick = async () => {
    this.tableLoading = true;
    try {
      await this.loadPointLog();
    } catch (ex) {}
    this.tableLoading = false;
  };

  loadPointLog = async () => {
    this.loading = true;
    this.tableLoading = true;
    this.sub && this.sub.unsubscribe();

    this.sub = this.userService
      .getUserBalanceList(
        this.pageNumber - 1,
        this.pageSize,
        this.user.userId,
        this.balanceType,
        this.date,
      )
      .subscribe(
        page => {
          this.page = page;
          this.totalCount = this.page.totalDataCount;
          this.dataLoaded = true;
          this.loading = false;
          this.tableLoading = false;
        },
        _ => {
          this.loading = false;
          this.tableLoading = false;
        },
      );
  };

  gameNameToStr = (balance: UserBalanceLog) => {
    if (balance.balanceType === 'PARTNER_CHARGE' || balance.balanceType === 'PARTNER_REFUND') {
      return balance.gameName;
    }
    if (balance.balanceSubType === 'MANUAL') {
      return '-';
    }
    return balance.gameName;
  };

  gameRoundToStr = (balance: UserBalanceLog) => {
    if (balance.balanceSubType === 'POWER_BALL') {
      return balance.raceTitle;
    } else if (balance.balanceSubType === 'CASINO' || balance.balanceSubType === 'SLOT') {
      return balance.gameRound;
    }
    return '-';
  };

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
    this.subs.map(sub => sub && sub.unsubscribe());
  }
}
