import { Component, Input, OnInit } from '@angular/core';

import { Casino, User } from '@models';
import { UserService } from '@services';

@Component({
  selector: 'gz-user-point-rate',
  templateUrl: './user-point-rate.component.html',
  styleUrls: ['./user-point-rate.component.sass'],
})
export class UserPointRateComponent implements OnInit {
  @Input() casinoList: Casino[] = [];
  @Input() user: User;
  gameGroupId: number;

  myUser: User;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getCachedUserInfo().then(user => (this.myUser = user));
  }

  get showCasino(): boolean {
    if (!this.myUser) {
      return false;
    }
  }
}
